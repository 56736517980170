<template>
  <div class="weatherLine">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/line");
export default {
  name: "dateLine",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    date: Array,
    peakPower: Array,
    valleyPeriodPower: Array,
    flatPower: Array,
    psPower:Array,
    load: Array,
  },
  data() {
    return {};
  },
  mounted() {
    this.drawWeatherLine();
    window.addEventListener("resize", function () {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
    // console.log(this.peakPower,this.load)
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  watch: {
    peakPower() {
      this.drawWeatherLine();
    },
    valleyPeriodPower() {
      this.drawWeatherLine();
    },
    flatPower() {
      this.drawWeatherLine();
    },
    psPower(){
      this.drawWeatherLine();
    },
    load() {
      this.drawWeatherLine();
    },
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      // 绘制图表
      myChart.setOption(
        {
          tooltip: {
            trigger: "axis",
          },
          legend: {
          top: 10,
          itemWidth: 8,
          itemHeight: 8,
          icon: "circle",
          left: "center",
          padding: 0,
          textStyle: {
            color: "inherit",
            fontSize: 12,
            padding: [2, 0, 0, 0],
          },
        },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          // toolbox: {
          //     feature: {
          //         saveAsImage: {},
          //     },
          // },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: this.date,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: "尖、峰期平均负荷（kW）",
              type: "line",
              data: this.psPower,
              itemStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                type: "linear",
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0, 167, 233,0.3)",
                  },
                  {
                    offset: 1,
                    color: "#00D7E9",
                  },
                ],
              },
            },
            },
            {
              name: "谷期平均负荷（kW）",
              type: "line",
              data: this.valleyPeriodPower,
              itemStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                type: "linear",
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(253, 210, 6,0.3)",
                  },
                  {
                    offset: 1,
                    color: "rgba(253, 210, 6,1)",
                  },
                ],
              },
            },
            },
            {
              name: "平期平均负荷（kW）",
              type: "line",
              data: this.flatPower,
              itemStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                type: "linear",
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(116, 21, 219,0.3)",
                  },
                  {
                    offset: 1,
                    color: "rgba(116, 21, 219,1)",
                  },
                ],
              },
            },
            },
            {
              name: "线路总负荷（kW）",
              type: "line",
              data: this.load,
              itemStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                type: "linear",
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(245, 94, 94,0.3)",
                  },
                  {
                    offset: 1,
                    color: "rgba(245, 94, 94,1)",
                  },
                ],
              },
            },
            },
          ],
        },
        true
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
.weatherLine {
    height: 100%;
    width: 100%;
}
</style>
