<template>
  <div :class="bgTheme">
    <div class="report_box">
      <meta
        name="viewport"
        content="user-scalable=0,width=device-width, initial-scale=1.0"
      />
      <div class="theme" style="float:left">
        <el-form>
          <el-form-item label="报告主题切换">
            <el-switch
              v-model="themeVal"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-icon-class="iconfont icon-baitian-qing"
              inactive-icon-class="iconfont icon-yejian"
              >
            </el-switch>
          </el-form-item>
          <el-form-item label="报告下载">
            <i class="iconfont icon-yunduanxiazai" @click="toAzure(0)"></i>
          </el-form-item>
        </el-form>
        
      </div>
      <div class="report">
        <div class="content" id="pdf-container">
          <div
            class="title"
            style="
              font-size: 26px;
              text-align: center;
              margin: 20px;
              color: #21a3fe;
            "
          >
            <div style="margin 20px 0">投资建议报告</div>
          </div>
          <div class="introduction">
            <label-name-2 name="项目概况"></label-name-2>
            <div class="projectContent">
              <transverse-table
                :tableData="projectDesc"
                :bgTheme="bgTheme"
              ></transverse-table>
            </div>
            <div class="projectContent">
              <div class="secTitle">项目用电量情况</div>

              <!-- <div style="text-indent: 2em">本次报告生成方案以月工作天数{{workSum}}天计算</div> -->
              <template v-for="(v, i) in info">
                <div :key="i" class="pointBox">
                  <div class="point">
                    第 <span class="num">{{ i + 1 }}</span
                    >个计量点
                  </div>
                  <div class="table">
                    <lable-name name="峰谷平月用电量"></lable-name>
                    <div class="tableContent">
                      <div style="width: 100%">
                        <el-table
                          :data="v"
                          style="width: 100%"
                          :header-cell-style="{
                            textAlign: 'center',
                            color: '#111',
                            fontSize: '12px',
                          }"
                          :cell-style="{
                            textAlign: 'center',
                            color: '#111',
                            fontSize: '12px',
                          }"
                          :row-style="{ height: '10px' }"
                        >
                          <el-table-column prop="date" label="日期">
                          </el-table-column>
                          <el-table-column
                            prop="peakPower"
                            label="高峰用电量（Kw/h）"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="sharpPower"
                            label="尖峰用电量（Kw/h）"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="valleyPeriodPower"
                            label="谷段用电量（Kw/h）"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="flatPower"
                            label="平段用电量（Kw/h）"
                          >
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                  </div>
                  <div class="table">
                    <lable-name name="峰谷平日用电量"></lable-name>
                    <div class="tableContent">
                      <div class="el-table">
                        <el-table
                          :data="dayUse[i]"
                          style="width: 100%"
                          :header-cell-style="{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: '#111',
                          }"
                          :cell-style="{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: '#111',
                          }"
                        >
                          <el-table-column prop="date" label="日期">
                          </el-table-column>
                          <el-table-column
                            prop="peakPower"
                            label="高峰用电量（Kw/h）"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="sharpPower"
                            label="尖峰用电量（Kw/h）"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="valleyPeriodPower"
                            label="谷段用电量（Kw/h）"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="flatPower"
                            label="平段用电量（Kw/h）"
                          >
                          </el-table-column>
                          <el-table-column prop="sum" label="总用电量（Kw/h）">
                          </el-table-column>
                        </el-table>
                      </div>
                      <div class="table-line">
                        <bar
                          :date="dayUseChart[i].date"
                          :peakPower="dayUseChart[i].peakPower"
                          :valleyPeriodPower="dayUseChart[i].valleyPeriodPower"
                          :flatPower="dayUseChart[i].flatPower"
                          :sharpPower="dayUseChart[i].sharpPower"
                        ></bar>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="table">
                  <lable-name name="峰谷平月平均用电负荷"></lable-name>
                  <div class="tableContent">
                    <div>
                      <el-table
                        :data="power2[i]"
                        style="width: 100%"
                        :header-cell-style="{ textAlign: 'center' }"
                        :cell-style="{ textAlign: 'center' }"
                      >
                        <el-table-column prop="date" label="日期">
                        </el-table-column>
                        <el-table-column
                          prop="peakPower"
                          label="峰期月平均负荷（kW）"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="valleyPeriodPower"
                          label="谷期月平均负荷（kW）"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="flatPower"
                          label="平期月平均负荷（kW）"
                        >
                        </el-table-column>
                        <el-table-column prop="sum" label="总月平均负荷（kW）">
                        </el-table-column>
                      </el-table>
                    </div>

                    <div class="table-line">
                      <dataLine
                        :date="powerMonth[i].date"
                        :peakPower="powerMonth[i].peakPower"
                        :valleyPeriodPower="powerMonth[i].valleyPeriodPower"
                        :flatPower="powerMonth[i].flatPower"
                        :load="powerMonth[i].load"
                      ></dataLine>
                    </div>
                  </div>
                </div> -->
                  <div class="table">
                    <lable-name name="峰谷平日平均用电负荷"></lable-name>
                    <div class="tableContent">
                      <div class="el-table">
                        <el-table
                          :data="power3[i]"
                          style="width: 100%"
                          :header-cell-style="{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: '#111',
                          }"
                          :cell-style="{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: '#111',
                          }"
                        >
                          <el-table-column prop="date" label="日期">
                          </el-table-column>
                          <el-table-column
                            prop="psPower"
                            label="尖、峰期负荷（kW）"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="valleyPeriodPower"
                            label="谷期负荷（kW）"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="flatPower"
                            label="平期负荷（kW）"
                          >
                          </el-table-column>
                          <!-- <el-table-column prop="sum" label="总负荷（kW）">
                        </el-table-column> -->
                        </el-table>
                      </div>
                      <div class="table-line">
                        <dataLine
                          :date="powerDay[i].date"
                          :valleyPeriodPower="powerDay[i].valleyPeriodPower"
                          :flatPower="powerDay[i].flatPower"
                          :psPower="powerDay[i].psPower"
                          :load="powerDay[i].load"
                        ></dataLine>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="secTitle">项目地电价</div>
              <div class="projectContent">
                该项目位于{{ address }}。其尖峰电价为
                <span class="important">{{
                  electricityPrices.sharpPrices
                }}</span
                >元/kwh，高峰电价为<span class="important">{{
                  electricityPrices.peakPrices
                }}</span
                >元/kwh,低谷电价为
                <span class="important">{{
                  electricityPrices.valleyPeriodPrices
                }}</span
                >元/kwh，平段电价为
                <span class="important">{{ electricityPrices.flatPrices }}</span
                >元/kwh，每天尖峰、高峰、谷段和平段时间间隔分别为：
                <span class="important">{{ electricityDate.sharpDate }}</span
                >小时、<span class="important">{{
                  electricityDate.peakDate
                }}</span
                >小时、<span class="important">{{
                  electricityDate.valleyPeriodDate
                }}</span
                >小时、
                <span class="important">{{ electricityDate.flatDate }}</span
                >小时。
              </div>
            </div>
            <template v-if="ruleForm.isCreate">
              <label-name-2 name="项目建设规模"></label-name-2>
              <!-- {{valleyLoadPoor}} -->
              <template v-for="(item, j) in valleyLoadPoor">
                <div class="projectContent device" :key="j">
                  该项目线路<span class="important">{{ j + 1 }}</span
                  >的尖、峰段日均负载平均值为<span class="important">{{
                    psPowerAver[j]
                  }}</span
                  >kW，谷段日均负载平均值为<span class="important">{{
                    valleyPowerAver[j]
                  }}</span
                  >kW，平段日均负载平均值为<span class="important">{{
                    flatPowerAver[j]
                  }}</span
                  >kW，谷电时段空余容量<span class="important">{{
                    valleyLoadPoor[j]
                  }}</span
                  >kw。
                  <template>
                    <span v-if="item < 0"> 无配置方案。 </span>
                    <span v-else>
                      根据该线路总的峰平谷用电功率，结合我司集装箱产品，建议储能系统建设为
                      <template v-for="(x, y) in device[j]">
                        <div
                          :key="y"
                          style="display: inline"
                          v-if="device[j].length > 1"
                        >
                          <span class="important">{{ x.num }}</span
                          >台<span class="important"
                            >{{ x.power }}kw/{{ x.capacity }}kWh</span
                          >的
                          <span class="important">{{
                            batteryType == 2 ? "新电池" : "梯次电池"
                          }}</span>
                          {{ x.istallWay }}储能产品<span
                            v-if="device[j].length - 1 != y"
                            >和</span
                          ><span v-else>。</span>
                        </div>
                        <div :key="y" style="display: inline" v-else>
                          <span class="important">{{ x.num }}</span
                          >台<span class="important"
                            >{{ x.power }}kw/{{ x.capacity }}kWh</span
                          >的
                          <span class="important">{{
                            batteryType == 2 ? "新电池" : "梯次电池"
                          }}</span
                          >{{ x.istallWay }}储能产品。
                        </div>
                      </template>
                    </span>
                  </template>
                </div>
              </template>
              <div class="projectContent img" v-if="deviceSum.power">
                <template v-if="isBox">
                  <img
                    src="@/assets/imgs/EnerCube1.jpg"
                    style="height: 120px"
                  />
                  <img
                    src="@/assets/imgs/EnerCube2.png"
                    style="height: 120px"
                  />
                </template>
                <template v-if="isOutdoor">
                  <img
                    src="@/assets/imgs/outdoor1.png"
                    alt=""
                    style="height: 120px"
                  />
                  <img
                    src="@/assets/imgs/outdoor2.jpg"
                    alt=""
                    style="height: 120px"
                  />
                </template>
              </div>
            </template>

            <template v-if="deviceSum.power && ruleForm.isCreate">
              <label-name-2 name="项目经济收益分析"></label-name-2>
              <div>
                <div class="projectContent" style="text-indent: 2em">
                  该项目总配置的容量为<span class="important">{{
                    deviceSum.capacity
                  }}</span
                  >kWh，总功率为<span class="important">{{
                    deviceSum.power
                  }}</span
                  >kW。按照一天充放电{{ chargeWay }}次、月工作天数{{
                    workSum
                  }}天、系统损耗率{{ systemData.efficiency }}、单次循环衰减率{{
                    systemData.singleAttenuation
                  }}、运维费占设备售价比{{
                    investment.operations
                  }}进行经济收益计算，10年内收益情况表如下表所示：
                </div>
                <div class="projectContent">
                  <el-table
                    :data="profitsSum"
                    style="width: 100%"
                    :header-cell-style="{
                      textAlign: 'center',
                      color: '#111',
                      fontSize: '13px',
                    }"
                    :cell-style="{
                      textAlign: 'center',
                      color: '#111',
                      fontSize: '12px',
                    }"
                    border
                  >
                    <el-table-column prop="years" label="第N年">
                    </el-table-column>
                    <el-table-column prop="spending" label="充电支出（元）">
                    </el-table-column>
                    <el-table-column prop="income" label="放电收入（元）">
                    </el-table-column>
                    <el-table-column
                      prop="operations"
                      label="运维费用（元）"
                    ></el-table-column>
                    <el-table-column prop="earnings" label="年度收益（元）">
                    </el-table-column>
                    <el-table-column
                      prop="sumPorfits"
                      label="累计收益（元）"
                    ></el-table-column>
                  </el-table>
                </div>
                <div class="projectContent">
                  <div class="projectContent">
                    本项目第一年月收益情况如下表：
                  </div>
                  <el-table
                    :data="monthPrice"
                    style="width: 100%"
                    :header-cell-style="{
                      textAlign: 'center',
                      color: '#111',
                      fontSize: '13px',
                    }"
                    :cell-style="{
                      textAlign: 'center',
                      color: '#111',
                      fontSize: '12px',
                    }"
                    border
                  >
                    <el-table-column prop="month" label="月份">
                    </el-table-column>
                    <el-table-column
                      prop="valleyCharge"
                      label="谷期充电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="valleyDischarge"
                      label="谷期放电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="valleyEarnings"
                      label="谷期收益（元）"
                    ></el-table-column>

                    <el-table-column
                      prop="flatCharge"
                      label="平期充电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="flatDischarge"
                      label="平期放电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="flatEarnings"
                      label="平期收益（元）"
                    ></el-table-column>
                    <el-table-column
                      prop="peakCharge"
                      label="峰期充电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="peakDischarge"
                      label="峰期放电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="peakEarnings"
                      label="峰期收益（元）"
                    ></el-table-column>
                    <el-table-column
                      prop="sharpCharge"
                      label="尖峰充电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="sharpDischarge"
                      label="尖峰放电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="sharpEarnings"
                      label="尖峰收益（元）"
                    ></el-table-column>
                    <el-table-column
                      prop="charge"
                      label="总充电量（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="discharge"
                      label="总放电量（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="earnings"
                      label="月总收益（元）"
                    ></el-table-column>
                  </el-table>
                </div>
                <div v-if="value == 1">
                  <div class="table-line">
                    <line-bar
                      :years="profitsSumChart.years"
                      :earnings="profitsSumChart.earnings"
                      :charge="profitsSumChart.charge"
                      :discharge="profitsSumChart.discharge"
                      :investment="profitsSumChart.investment"
                      :sumPorfits="profitsSumChart.sumPorfits"
                      :sumYearPorfits="profitsSumChart.sumYearPorfits"
                    >
                    </line-bar>
                  </div>
                  <div class="projectContent">本项目10年收益情况如下表：</div>
                  <div class="projectContent">
                    <el-table
                      :data="sum"
                      style="width: 100%"
                      :header-cell-style="{
                        textAlign: 'center',
                        color: '#111',
                        fontSize: '13px',
                      }"
                      :cell-style="{
                        textAlign: 'center',
                        color: '#111',
                        fontSize: '12px',
                      }"
                      border
                    >
                      <el-table-column prop="investment" label="初始投资（元）">
                      </el-table-column>
                      <el-table-column
                        prop="sumYearPorfits"
                        label="收益总计（元）"
                      ></el-table-column>
                    </el-table>
                  </div>
                </div>
                <div v-else-if="value == 2">
                  <div class="table-line">
                    <line-bar-sec
                      :years="profitsSumChart.years"
                      :earnings="profitsSumChart.earnings"
                      :charge="profitsSumChart.charge"
                      :discharge="profitsSumChart.discharge"
                      :investment="profitsSumChart.investment"
                      :siteEarning="profitsSumChart.siteEarning"
                      :sumPorfits="profitsSumChart.sumPorfits"
                      :sumYearPorfits="profitsSumChart.sumYearPorfits"
                      :unsiteEarning="profitsSumChart.unsiteEarning"
                    >
                    </line-bar-sec>
                  </div>
                  <div class="projectContent">本项目10年收益情况如下表：</div>
                  <div class="projectContent">
                    <el-table
                      :data="sum"
                      style="width: 100%"
                      :header-cell-style="{
                        textAlign: 'center',
                        color: '#111',
                        fontSize: '13px',
                      }"
                      :cell-style="{
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#111',
                      }"
                      border
                    >
                      <el-table-column prop="investment" label="初始投资（元）">
                      </el-table-column>
                      <el-table-column
                        prop="unsiteEarning"
                        label="建设方收益（元）"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="siteEarning"
                        label="场地分成（元）"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="sumYearPorfits"
                        label="收益总计（元）"
                      ></el-table-column>
                    </el-table>
                  </div>
                </div>
                <div v-else-if="value == 3">
                  <div class="table-line">
                    <line-bar-third
                      :years="profitsSumChart.years"
                      :charge="profitsSumChart.charge"
                      :discharge="profitsSumChart.discharge"
                      :investment="profitsSumChart.investment"
                      :aEarnings="profitsSumChart.aEarnings"
                      :bEarnings="profitsSumChart.bEarnings"
                      :sumPorfits="profitsSumChart.sumPorfits"
                      :aInvest="profitsSumChart.aInvest"
                      :bInvest="profitsSumChart.bInvest"
                    >
                    </line-bar-third>
                  </div>

                  <div class="projectContent">
                    本项目10年投资收益情况如下表：
                  </div>
                  <div class="projectContent">
                    <el-table
                      :data="sum"
                      style="width: 100%"
                      :header-cell-style="{
                        textAlign: 'center',
                        color: '#111',
                        fontSize: '13px',
                      }"
                      :cell-style="{
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#111',
                      }"
                      border
                    >
                      <el-table-column prop="aInvest" label="资金方投资（元）">
                      </el-table-column>
                      <el-table-column
                        prop="aEarnings"
                        label="资金方收益（元）"
                      ></el-table-column>
                      <el-table-column prop="bInvest" label="建设方投资（元）">
                      </el-table-column>
                      <el-table-column
                        prop="bEarnings"
                        label="建设方收益（元）"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="sumYearPorfits"
                        label="收益总计（元）"
                      ></el-table-column>
                    </el-table>
                  </div>
                </div>
                <div v-else style="color: #ccc; text-align: center">
                  暂无数据
                </div>
              </div>
            </template>
            <div v-if="ruleForm.isForce" class="forceDiv">
              <label-name-2 name="项目建设规模"></label-name-2>
              <!-- {{valleyLoadPoor}} -->
              <template v-for="(item, j) in valleyLoadPoor">
                <div class="projectContent device" :key="j">
                  该项目线路<span class="important">{{ j + 1 }}</span
                  >的尖、峰段日均负载平均值为<span class="important">{{
                    psPowerAver[j]
                  }}</span
                  >kW，谷段日均负载平均值为<span class="important">{{
                    valleyPowerAver[j]
                  }}</span
                  >kW，平段日均负载平均值为<span class="important">{{
                    flatPowerAver[j]
                  }}</span
                  >kW，谷电时段空余容量<span class="important">{{
                    valleyLoadPoor[j]
                  }}</span
                  >kw。
                  <template>
                    <span>
                      根据该线路总的峰平谷用电功率，结合我司集装箱产品，建议储能系统建设为
                      <span class="important">{{ ruleForm.deviceNum }}</span
                      >台<span class="important"
                        >{{
                          deviceList[ruleForm.deviceType].name
                        }}储能产品。</span
                      >
                    </span>
                  </template>
                </div>
              </template>
              <div class="projectContent img" v-if="deviceSum.power">
                <template v-if="isBox">
                  <img
                    src="@/assets/imgs/EnerCube1.jpg"
                    style="height: 120px"
                  />
                  <img
                    src="@/assets/imgs/EnerCube2.png"
                    style="height: 120px"
                  />
                </template>
                <template v-if="isOutdoor">
                  <img
                    src="@/assets/imgs/outdoor1.png"
                    alt=""
                    style="height: 120px"
                  />
                  <img
                    src="@/assets/imgs/outdoor2.jpg"
                    alt=""
                    style="height: 120px"
                  />
                </template>
              </div>
              <div class="firstTitle" style="margin-left: 20px">
                <label-name-2 name="配置项目经济收益分析"></label-name-2>
              </div>
              <div>
                <div class="projectContent" style="text-indent: 2em">
                  该项目总配置的容量为<span class="important">{{
                    deviceSum1.capacity
                  }}</span
                  >kWh，总功率为<span class="important">{{
                    deviceSum1.power
                  }}</span
                  >kW。按照一天充放电{{ chargeWay }}次、月工作天数{{
                    workSum
                  }}天、系统损耗率{{ systemData.efficiency }}、单次循环衰减率{{
                    systemData.singleAttenuation
                  }}、运维费占设备售价比{{
                    investment.operations
                  }}进行经济收益计算，10年内收益情况表如下表所示：
                </div>
                <div class="projectContent">
                  <el-table
                    :data="profitsSum1"
                    style="width: 100%"
                    :header-cell-style="{
                      textAlign: 'center',
                      color: '#111',
                      fontSize: '13px',
                    }"
                    :cell-style="{
                      textAlign: 'center',
                      color: '#111',
                      fontSize: '12px',
                    }"
                    border
                  >
                    <el-table-column prop="years" label="第N年">
                    </el-table-column>
                    <el-table-column prop="spending" label="充电支出（元）">
                    </el-table-column>
                    <el-table-column prop="income" label="放电收入（元）">
                    </el-table-column>
                    <el-table-column
                      prop="operations"
                      label="运维费用（元）"
                    ></el-table-column>
                    <el-table-column prop="earnings" label="年度收益（元）">
                    </el-table-column>
                    <el-table-column
                      prop="sumPorfits"
                      label="累计收益（元）"
                    ></el-table-column>
                  </el-table>
                </div>
                <div class="projectContent">
                  <div class="projectContent">
                    本项目第一年月收益情况如下表：
                  </div>
                  <el-table
                    :data="monthPrice1"
                    style="width: 100%"
                    :header-cell-style="{
                      textAlign: 'center',
                      color: '#111',
                      fontSize: '13px',
                    }"
                    :cell-style="{
                      textAlign: 'center',
                      color: '#111',
                      fontSize: '12px',
                    }"
                    border
                  >
                    <el-table-column prop="month" label="月份">
                    </el-table-column>
                    <el-table-column
                      prop="valleyCharge"
                      label="谷期充电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="valleyDischarge"
                      label="谷期放电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="valleyEarnings"
                      label="谷期收益（元）"
                    ></el-table-column>

                    <el-table-column
                      prop="flatCharge"
                      label="平期充电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="flatDischarge"
                      label="平期放电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="flatEarnings"
                      label="平期收益（元）"
                    ></el-table-column>
                    <el-table-column
                      prop="peakCharge"
                      label="峰期充电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="peakDischarge"
                      label="峰期放电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="peakEarnings"
                      label="峰期收益（元）"
                    ></el-table-column>
                    <el-table-column
                      prop="sharpCharge"
                      label="尖峰充电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="sharpDischarge"
                      label="尖峰放电（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="sharpEarnings"
                      label="尖峰收益（元）"
                    ></el-table-column>
                    <el-table-column
                      prop="charge"
                      label="总充电量（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="discharge"
                      label="总放电量（kWh）"
                    ></el-table-column>
                    <el-table-column
                      prop="earnings"
                      label="月总收益（元）"
                    ></el-table-column>
                  </el-table>
                </div>
                <div v-if="value == 1">
                  <div class="table-line">
                    <line-bar
                      :years="profitsSumChart1.years"
                      :earnings="profitsSumChart1.earnings"
                      :charge="profitsSumChart1.charge"
                      :discharge="profitsSumChart1.discharge"
                      :investment="profitsSumChart1.investment"
                      :sumPorfits="profitsSumChart1.sumPorfits"
                      :sumYearPorfits="profitsSumChart1.sumYearPorfits"
                    >
                    </line-bar>
                  </div>
                  <div class="projectContent">本项目10年收益情况如下表：</div>
                  <div class="projectContent">
                    <el-table
                      :data="sum1"
                      style="width: 100%"
                      :header-cell-style="{
                        textAlign: 'center',
                        color: '#111',
                        fontSize: '13px',
                      }"
                      :cell-style="{
                        textAlign: 'center',
                        color: '#111',
                        fontSize: '12px',
                      }"
                      border
                    >
                      <el-table-column prop="investment" label="初始投资（元）">
                      </el-table-column>
                      <el-table-column
                        prop="sumYearPorfits"
                        label="收益总计（元）"
                      ></el-table-column>
                    </el-table>
                  </div>
                </div>
                <div v-else-if="value == 2">
                  <div class="table-line">
                    <line-bar-sec
                      :years="profitsSumChart1.years"
                      :earnings="profitsSumChart1.earnings"
                      :charge="profitsSumChart1.charge"
                      :discharge="profitsSumChart1.discharge"
                      :investment="profitsSumChart1.investment"
                      :siteEarning="profitsSumChart1.siteEarning"
                      :sumPorfits="profitsSumChart1.sumPorfits"
                      :sumYearPorfits="profitsSumChart1.sumYearPorfits"
                      :unsiteEarning="profitsSumChart1.unsiteEarning"
                    >
                    </line-bar-sec>
                  </div>
                  <div class="projectContent">本项目10年收益情况如下表：</div>
                  <div class="projectContent">
                    <el-table
                      :data="sum1"
                      style="width: 100%"
                      :header-cell-style="{
                        textAlign: 'center',
                        color: '#111',
                        fontSize: '13px',
                      }"
                      :cell-style="{
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#111',
                      }"
                      border
                    >
                      <el-table-column prop="investment" label="初始投资（元）">
                      </el-table-column>
                      <el-table-column
                        prop="unsiteEarning"
                        label="建设方收益（元）"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="siteEarning"
                        label="场地分成（元）"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="sumYearPorfits"
                        label="收益总计（元）"
                      ></el-table-column>
                    </el-table>
                  </div>
                </div>
                <div v-else-if="value == 3">
                  <div class="table-line">
                    <line-bar-third
                      :years="profitsSumChart.years"
                      :charge="profitsSumChart.charge"
                      :discharge="profitsSumChart.discharge"
                      :investment="profitsSumChart.investment"
                      :aEarnings="profitsSumChart.aEarnings"
                      :bEarnings="profitsSumChart.bEarnings"
                      :sumPorfits="profitsSumChart.sumPorfits"
                      :aInvest="profitsSumChart.aInvest"
                      :bInvest="profitsSumChart.bInvest"
                    >
                    </line-bar-third>
                  </div>

                  <div class="projectContent">
                    本项目10年投资收益情况如下表：
                  </div>
                  <div class="projectContent">
                    <el-table
                      :data="sum"
                      style="width: 100%"
                      :header-cell-style="{
                        textAlign: 'center',
                        color: '#111',
                        fontSize: '13px',
                      }"
                      :cell-style="{
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#111',
                      }"
                      border
                    >
                      <el-table-column prop="aInvest" label="资金方投资（元）">
                      </el-table-column>
                      <el-table-column
                        prop="aEarnings"
                        label="资金方收益（元）"
                      ></el-table-column>
                      <el-table-column prop="bInvest" label="建设方投资（元）">
                      </el-table-column>
                      <el-table-column
                        prop="bEarnings"
                        label="建设方收益（元）"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="sumYearPorfits"
                        label="收益总计（元）"
                      ></el-table-column>
                    </el-table>
                  </div>
                </div>
                <div v-else style="color: #ccc; text-align: center">
                  暂无数据
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="copyright">版权所有：深圳市未蓝新能源科技公司</div>
          <div class="copyright">
            Copyright Shenzhen Weilan New Energy Technology Co., Ltd. All Rights
            Reserved
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHour } from "@/utils/getHour.js";
import LableName from "./components/LableName.vue";
import { roundFix } from "@/utils/utils.js";
import TransverseTable from "./components/TransverseTable.vue";
import dataLine from "./components/Line.vue";
import bar from "./components/Bar.vue";
// import profitsLine from "../components/profitsLine.vue";
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
import LineBar from "./components/LineBar.vue";
// import LineBar from '../components/LineBar2.vue';
import LineBarSec from "./components/LineBar2.vue";
import LineBarThird from "./components/LineBar3.vue";
import LabelName2 from "./components/labelName2.vue";
import { handleFileSelect } from "./js/toAzure.js";
import { deviceModelQuery } from "../../api/siteList";
export default {
  components: {
    LableName,
    TransverseTable,
    dataLine,
    bar,
    LineBar,
    LineBarSec,
    LineBarThird,
    LabelName2,
  },
  data() {
    return {
      themeVal: false,
      // 项目基础信息
      projectDesc: [
        {
          name: "项目名称",
          value: "",
        },
        {
          name: "项目所在地",
          value: "",
        },
        {
          name: "项目描述",
          value: "",
        },
      ],
      chargeWay: 0,
      value: 0,
      ruleForm: {}, //输入的所有数据
      dayUse: [], //日用电量
      ruleFormInfo: [], //输入的每个计量点数据
      info: [], // 所有用电量数组
      ruleFormInfo1: [], //计算出总值的用电量
      electricity: [],
      electricityPrices: {}, // 当地电价
      electricityDate: {
        peakDate: 0,
        sharpDate: 0,
        valleyPeriodDate: 0,
        flatDate: 0,
      }, // 峰谷平时间间隔
      peakPowerAver: [], // 峰段平均用电功率
      psPowerAver: [],
      flatPowerAver: [], //平段平均用电功率
      valleyPowerAver: [],
      peakUseAver: [],
      loadList: [], //负载功率
      valleyLoadPoor: [], // 谷电时段空余
      name: "", //项目名称
      desc: "", //项目描述
      power: [], //月用电量图数据
      powerMonth: [], //月用电功率图数据
      powerDay: [], //日用电功率图数据
      dayUseChart: [], //日用电量图数据
      address: "", //项目地址
      power2: [], //月用电功率表数据
      power3: [], //日用电功率表数据
      //第10年总收益情况
      sum: [],
      sum1: [],
      deviceSum: {
        capacity: 0,
        power: 0,
      },
      //自我设备配置
      deviceSum1: {
        capacity: 0,
        power: 0,
      },
      city: [
        {
          name: "广东省汕头市",
          peakTime: 7,
          valleyPeriodTime: 8,
          flatTime: 9,
          peakMoney: 0.9608,
          valleyPeriodMoney: 0.2148,
          flatMoney: 0.5652,
        },
      ],
      deviceList: [], //公司产品列表
      device: [], //线路推荐产品列表
      workDay: 310, //年工作天数
      workSum: 0,
      systemData: {}, //系统效率和损耗
      investment: {}, //投资
      investment1: {}, //投资
      profits: [], //10年利润表数据
      profitsChart: [], //10年利润图数据
      profitsSum: [],
      profitsSum1: [],
      profitsSumChart: {
        years: [],
        // spending: [],
        // income: [],
        earnings: [],
        investment: [],
        operations: [],
        charge: [],
        discharge: [],
        aEarnings: [],
        bEarnings: [],
        siteEarning: [],
        unsiteEarning: [],
        sumPorfits: [],
        sumYearPorfits: [],
        aInvest: [],
        bInvest: [],
      },
      profitsSumChart1: {
        years: [],
        // spending: [],
        // income: [],
        earnings: [],
        investment: [],
        operations: [],
        charge: [],
        discharge: [],
        aEarnings: [],
        bEarnings: [],
        siteEarning: [],
        unsiteEarning: [],
        sumPorfits: [],
        sumYearPorfits: [],
        aInvest: [],
        bInvest: [],
      },
      loss: [1, 0.97, 0.95, 0.92, 0.89, 0.86, 0.84, 0.81, 0.78, 0.75], //10年设备损耗率
      model: [
        {
          num: 1,
          mod: "企业自投",
          ad: "一次性资金投入大，收益高",
        },
        {
          num: 2,
          mod: "投资方投资",
          ad: "无需现金投入，提供场地，相对收益较低（一般10%）",
        },
        {
          num: 3,
          mod: "甲乙双方共同投资",
          ad: "甲乙双方按比例投资，按比例收益分成",
        },
      ],
      spreads: {
        //电价差值
        peakValleySpreads: 0, //峰谷电价差
        peakFlatSpreads: 0, //峰平电价差
        spreadsSum: 0, //峰谷电价差、峰平电价差之和
      },
      isPass: false,
      // 项目用电量情况 柱状图数据
      seriesMonthData: [], //峰谷平月用电量
      seriesDayData: [], //峰谷平日用电量
      dateTime: "", //下载时间
      isUpload: false,
      hasShark: false,
      sharpMonth: [],
      sharpSum: 0,
      hasDevice: false,
      isBox: false,
      isOutdoor: false,
      flatLoad: [],
      valleyL:[],
      date: [],
      batteryType: 1,
      firstChargeDate: 0,
      firstChargePrices: 0,
      firstChargeName: "",
      secondChargeDate: 0,
      secondChargePrices: 0,
      secondChargeName: "",
      monthPrice: [],
      monthPrice1: [],
      object: {
        pageNumber: 1,
        pageSize: null,
        para: {},
      },
    };
  },
  created() {
    
    
    // // this.checked();
    // this.getProfits();
    // this.getProfits2();
  },
  mounted() {
    this.getParams(); //获取提交的数据
    // this.getPower2();
    // this.getPower3();
    // this.getDayUse();
    // this.getPsAverage();
    // this.getPeakAverage();
    // this.getValleyAverage();
    // this.getFlatAverage();
    // // this.getFlatAverage();
    // this.getValleyLoadPoor();
    // this.getDevice(); //获取设备
    // this.getLoss(); //获得年损耗
    // this.getDeviceSum();
    // this.getBoxInvest();
    // this.chooseProfits();
  },
  computed: {
    bgTheme() {
      return !this.themeVal ? "blackTheme" : "whiteTheme";
    },
  },
  methods: {
    getHours() {
      console.log(this.date);
      // debugger;
      this.date.forEach((v, i) => {
        // let obj = {
        //   duration: 0,
        //   type: "",
        //   state: "",
        // };
        v.duration = getHour(v.startTime, v.endTime);
        if (v.value === "sharp") {
          this.electricityDate.sharpDate = roundFix(
            parseFloat(
              parseFloat(this.electricityDate.sharpDate) +
                parseFloat(v.duration)
            )
          );
        } else if (v.value === "peak") {
          this.electricityDate.peakDate = roundFix(
            parseFloat(
              parseFloat(this.electricityDate.peakDate) + parseFloat(v.duration)
            )
          );
        } else if (v.value === "valley") {
          if (i === 0) {
            this.firstChargeName = "valley";
            this.firstChargeDate = v.duration;
            this.firstChargePrices = this.electricityPrices.valleyPeriodPrices;
          }
          this.electricityDate.valleyPeriodDate = roundFix(
            parseFloat(
              parseFloat(this.electricityDate.valleyPeriodDate) +
                parseFloat(v.duration)
            )
          );
        } else if (v.value === "flat") {
          if (i === 0) {
            this.firstChargeName = "flat";
            this.firstChargeDate = v.duration;
            this.firstChargePrices = this.electricityPrices.flatDate;
          }
          this.electricityDate.flatDate = roundFix(
            parseFloat(
              parseFloat(this.electricityDate.flatDate) + parseFloat(v.duration)
            )
          );
        }
      });
      console.log(
        this.electricityDate,
        222222222222222,
        this.firstChargeDate,
        this.firstChargePrices
      );
      this.getSecondCharge();
    },
    getSecondCharge() {
      // var flag=false;
      var i = 0;
      for (i; i < this.date.length; i++) {
        if (this.date[i].value === "peak" || this.date[i].value === "sharp") {
          if (i == this.date.length - 1) {
            break;
          } else {
            if (
              this.date[i + 1].value == "valley" ||
              this.date[i + 1].value == "flat"
            ) {
              break;
            }
          }
        }
      }
      var j = this.date.length - 1;
      for (j; j >= 0; j--) {
        if (this.date[j].value === "peak" || this.date[j].value === "sharp") {
          if (j == 0) {
            break;
          } else {
            if (
              this.date[j - 1].value == "valley" ||
              this.date[j - 1].value == "flat"
            ) {
              break;
            }
          }
        }
      }
      if (i < j) {
        i = i + 1;
        for (i; i < j; i++) {
          if (this.date[i].value === "flat") {
            this.secondChargeName = "flat";
            this.secondChargeDate = this.date[i].duration;
            this.secondChargePrices = this.electricityPrices.flatPrices;
          } else if (this.date[i].value === "valley") {
            this.secondChargeName = "valley";
            this.secondChargeDate = this.date[i].duration;
            this.secondChargePrices = this.electricityPrices.valleyPeriodPrices;
          } else {
            break;
          }
        }
      }
      console.log(
        this.secondChargeDate,
        this.secondChargePrices,
        "-------------7777"
      );
    },
    async getDeviceList() {
      var x = await deviceModelQuery(this.object);
      this.deviceList = x.data.data.content;
      console.log(x.data.data,'------------------',"sdsssssss") 
    },
    async getParams() {
      var x = await deviceModelQuery(this.object);
      this.deviceList = x.data.data.content;
      console.log(x.data.data,'------------------',"sdsssssssgetParams") 

      this.ruleForm = JSON.parse(localStorage.getItem("ruleForm"));
      // var x = await deviceModelQuery({ para: {} });
      // // this.deviceList = res.data.data.content;
      // console.log(x.data.data,'------------------')
      // this.deviceList = JSON.parse(localStorage.getItem("deviceList"));
      this.date = JSON.parse(localStorage.getItem("date"));
      console.log(this.deviceList);
      this.ruleFormInfo = this.ruleForm.numArr;
      this.electricityPrices = this.ruleForm.electricityPrices;
      this.batteryType = this.ruleForm.batteryType;
      console.log(this.batteryType, "this.batteryType");
      // this.electricityDate = this.ruleForm.electricityDate;
      this.address = this.ruleForm.address;
      this.name = this.ruleForm.name;
      this.systemData = this.ruleForm.systemData;
      this.investment = this.ruleForm.investment;
      // console.log(this.investment);
      this.projectDesc[0].value = this.ruleForm.name;
      this.projectDesc[1].value = this.ruleForm.address;
      this.projectDesc[2].value = this.ruleForm.desc;
      this.workSum = this.ruleForm.workDay;
      this.chargeWay = this.ruleForm.chargeWay;
      console.log(this.chargeWay);
      // console.log(this.workSum);
      this.workDay = this.ruleForm.workDay * 12;
      this.ruleFormInfo.forEach((v) => {
        this.loadList.push(v.load);
        this.info.push(v.info);
      });
      this.isUpload = JSON.parse(localStorage.getItem("isUpload"));
      this.hasShark = this.ruleForm.hasShark;
      this.sharpMonth = JSON.parse(localStorage.getItem("sharpMonth"));
      // console.log("*****************", this.hasShark, this.sharpMonth);
      // console.log("------------", this.loadList, this.info, this.workDay);
      this.value = this.ruleForm.value;
      // console.log("*********************", this.value, this.investment);
      for (let i = 0; i < this.info.length; i++) {
        this.info[i].forEach((v) => {
          // console.log(v);
          v.psPower = parseFloat(
            parseFloat(v.peakPower) + parseFloat(v.sharpPower)
          );
        });
      }
      // console.log(this.info);
      this.info.forEach((v) => {
        let obj = {
          date: [],
          valleyPeriodPower: [],
          peakPower: [],
          flatPower: [],
          psPower: [],
        };
        for (let j = 0; j < v.length; j++) {
          obj.date.push(v[j].date);
          obj.valleyPeriodPower.push(v[j].valleyPeriodPower);
          obj.peakPower.push(v[j].peakPower);
          obj.flatPower.push(v[j].flatPower);
          obj.psPower.push(v[j].psPower);
        }
        this.power.push(obj);
      });
      console.log(this.power);
      this.getHours();
      // 计算电价差
      this.getPower2();
    this.getPower3();
    this.getDayUse();
    this.getPsAverage();
    this.getPeakAverage();
    this.getValleyAverage();
    this.getFlatAverage();
    // this.getFlatAverage();
    this.getValleyLoadPoor();
    this.getDevice(); //获取设备
    this.getLoss(); //获得年损耗
    this.getDeviceSum();
    this.getBoxInvest();
    this.chooseProfits();
    },
    getManyPdf() {
      if (this.isUpload) {
        this.toAzure(1, 0);
        // console.log(this.isUpload)
        localStorage.setItem("isUpload", JSON.stringify(false));
      }
    },
    // 获取每月的日用电量
    getDayUse() {
      for (let i = 0; i < this.info.length; i++) {
        let arr = [];
        this.info[i].forEach((k) => {
          let obj = {
            date: "", // 月份
            peakPower: 0, // 峰期电量
            valleyPeriodPower: 0, // 谷期电量
            flatPower: 0, // 平期电量
            psPower: 0,
            sharpPower: 0,
            sum: 0,
          };
          obj.date = k.date;
          obj.peakPower = (k.peakPower / this.workSum).toFixed(2);
          obj.valleyPeriodPower = (k.valleyPeriodPower / this.workSum).toFixed(
            2
          );
          obj.flatPower = (k.flatPower / this.workSum).toFixed(2);
          obj.sharpPower = (k.sharpPower / this.workSum).toFixed(2);
          obj.psPower = (k.psPower / this.workSum).toFixed(2);
          obj.sum = (
            parseFloat(obj.psPower) +
            parseFloat(obj.valleyPeriodPower) +
            parseFloat(obj.flatPower)
          ).toFixed(2);
          arr.push(obj);
        });
        this.dayUse.push(arr);
      }
      console.log(this.dayUse);
      this.dayUse.forEach((v) => {
        let obj = {
          date: [],
          valleyPeriodPower: [],
          peakPower: [],
          flatPower: [],
          sharpPower: [],
        };
        for (let j = 0; j < v.length; j++) {
          obj.date.push(v[j].date);
          obj.valleyPeriodPower.push(v[j].valleyPeriodPower);
          obj.peakPower.push(v[j].peakPower);
          obj.flatPower.push(v[j].flatPower);
          obj.sharpPower.push(v[j].sharpPower);
        }
        this.dayUseChart.push(obj);
      });
    },
    // 获取月平均负荷的数据
    getPower2() {
      for (let i = 0; i < this.info.length; i++) {
        let arr = [];
        this.info[i].forEach((k) => {
          let obj = {
            date: "", // 月份
            peakPower: 0, // 峰期电量
            valleyPeriodPower: 0, // 谷期电量
            flatPower: 0, // 平期电量
            psPower: 0,
            sharpPower: 0,
            sum: 0,
          };
          obj.date = k.date;
          if (this.electricityDate.peakDate == 0) {
            obj.peakPower = 0;
          } else {
            obj.peakPower = (
              k.peakPower / this.electricityDate.peakDate
            ).toFixed(2);
          }
          if (this.electricityDate.sharpDate == 0) {
            obj.sharpPower = 0;
          } else {
            obj.sharpPower = (
              k.sharpPower / this.electricityDate.sharpDate
            ).toFixed(2);
          }
          if (this.electricityDate.valleyPeriodDate == 0) {
            obj.valleyPeriodPower = 0;
          } else {
            obj.valleyPeriodPower = (
              k.valleyPeriodPower / this.electricityDate.valleyPeriodDate
            ).toFixed(2);
          }
          if (this.electricityDate.flatDate == 0) {
            obj.flatPower = 0;
          } else {
            obj.flatPower = (
              k.flatPower / this.electricityDate.flatDate
            ).toFixed(2);
          }
          let psDate = parseFloat(
            parseFloat(this.electricityDate.peakDate) +
              parseFloat(this.electricityDate.sharpDate)
          );
          obj.psPower = (k.psPower / psDate).toFixed(2);
          // console.log(k.psPower,psDate,obj.psPower);
          obj.sum = (
            parseFloat(obj.psPower) +
            parseFloat(obj.valleyPeriodPower) +
            parseFloat(obj.flatPower)
          ).toFixed(2);
          arr.push(obj);
          console.log(
            "this.electricityDate.flatDate",
            k.flatPower / this.electricityDate.flatDate
          );
        });
        this.power2.push(arr);
        console.log("this.power2", this.power2);
      }
      this.power2.forEach((v, i) => {
        let obj = {
          date: [],
          valleyPeriodPower: [],
          peakPower: [],
          flatPower: [],
          psPower: [],
          load: [],
        };
        // console.log(i,this.loadList[i]);
        for (let j = 0; j < v.length; j++) {
          obj.date.push(v[j].date);
          obj.valleyPeriodPower.push(v[j].valleyPeriodPower);
          obj.peakPower.push(v[j].peakPower);
          obj.flatPower.push(v[j].flatPower);
          obj.psPower.push(v[j].psPower);
          obj.load.push(this.loadList[i]);
          // console.log(i,this.loadList[i]);
        }
        this.powerMonth.push(obj);
      });
      // console.log(this.powerMonth);
    },
    // 获取日平均负荷的数据
    getPower3() {
      for (let i = 0; i < this.power2.length; i++) {
        let arr = [];
        this.power2[i].forEach((k) => {
          let obj = {
            date: "", // 月份
            peakPower: 0, // 峰期电量
            valleyPeriodPower: 0, // 谷期电量
            flatPower: 0, // 平期电量
            psPower: 0,
            sharpPower: 0,
            sum: 0,
          };
          obj.date = k.date;
          obj.peakPower = ((k.peakPower / this.workDay) * 12.0).toFixed(2);
          obj.valleyPeriodPower = (
            (k.valleyPeriodPower / this.workDay) *
            12.0
          ).toFixed(2);
          obj.flatPower = ((k.flatPower / this.workDay) * 12.0).toFixed(2);
          // console.log("obj.flatPower",k.flatPower);
          obj.sharpPower = ((k.sharpPower / this.workDay) * 12.0).toFixed(2);
          obj.psPower = (k.psPower / this.workSum).toFixed(2);
          obj.sum = (
            parseFloat(obj.psPower) +
            parseFloat(obj.valleyPeriodPower) +
            parseFloat(obj.flatPower)
          ).toFixed(2);
          arr.push(obj);
        });
        this.power3.push(arr);
      }
      this.power3.forEach((v, i) => {
        let obj = {
          date: [],
          valleyPeriodPower: [],
          peakPower: [],
          flatPower: [],
          psPower: [],
          sharpPower: [],
          load: [],
        };
        for (let j = 0; j < v.length; j++) {
          obj.date.push(v[j].date);
          obj.valleyPeriodPower.push(v[j].valleyPeriodPower);
          obj.peakPower.push(v[j].peakPower);
          obj.flatPower.push(v[j].flatPower);
          obj.psPower.push(v[j].psPower);
          obj.load.push(this.loadList[i]);
        }
        this.powerDay.push(obj);
        // console.log("----------", this.powerDay);
      });
      console.log("----------", this.powerDay, this.power3);
    },
    //求平均值
    average(nums) {
      if (nums.length != 0) {
        return (
          nums.reduce((a, b) => parseFloat(a) + parseFloat(b)) / nums.length
        );
      } else {
        return nums;
      }
    },
    getPsAverage() {
      for (var i = 0; i < this.powerDay.length; i++) {
        let aver = 0;
        let maxValue = Math.max.apply(null, this.powerDay[i].psPower);
        let minValue = Math.min.apply(null, this.powerDay[i].psPower);

        if (this.powerDay[i].psPower.length > 8) {
          let arr = [];
          this.powerDay[i].psPower.forEach((v) => {
            if (v != maxValue && v != minValue) {
              arr.push(v);
            }
          });
          // console.log(arr);
          aver = this.average(arr);
        } else {
          aver = this.average(this.powerDay[i].psPower);
        }
        this.psPowerAver.push(roundFix(aver));
      }
    },
    // 峰段日均功率平均值
    getPeakAverage() {
      for (var i = 0; i < this.powerDay.length; i++) {
        let aver = 0;
        let maxValue = Math.max.apply(null, this.powerDay[i].peakPower);
        let minValue = Math.min.apply(null, this.powerDay[i].peakPower);
        if (this.powerDay[i].peakPower.length > 8) {
          let arr = [];
          this.powerDay[i].peakPower.forEach((v) => {
            if (v != maxValue && v != minValue) {
              arr.push(v);
            }
          });
          // console.log(arr);
          aver = this.average(arr);
        } else {
          aver = this.average(this.powerDay[i].peakPower);
        }
        this.peakPowerAver.push(roundFix(aver));
      }
    },
    getFlatAverage() {
      // debugger;
      for (var i = 0; i < this.powerDay.length; i++) {
        let aver = 0;
        let maxValue = Math.max.apply(null, this.powerDay[i].flatPower);
        let minValue = Math.min.apply(null, this.powerDay[i].flatPower);
        if (this.powerDay[i].flatPower.length > 8) {
          let arr = [];
          this.powerDay[i].flatPower.forEach((v) => {
            if (v != maxValue && v != minValue) {
              arr.push(v);
            }
          });
          aver = this.average(arr);
        } else {
          aver = this.average(this.powerDay[i].flatPower);
        }
        console.log(
          "roundFix(aver)",
          roundFix(aver),
          aver,
          maxValue,
          minValue,
          this.powerDay[i]
        );
        this.flatPowerAver.push(roundFix(aver));
      }
      console.log("+++++++++++++++++++++++++++++++", this.flatPowerAver);
    },
    getValleyAverage() {
      for (var i = 0; i < this.powerDay.length; i++) {
        let aver = 0;
        let maxValue = Math.max.apply(null, this.powerDay[i].valleyPeriodPower);
        let minValue = Math.min.apply(null, this.powerDay[i].valleyPeriodPower);
        if (this.powerDay[i].valleyPeriodPower.length > 8) {
          let arr = [];
          this.powerDay[i].valleyPeriodPower.forEach((v) => {
            if (v != maxValue && v != minValue) {
              arr.push(v);
            }
          });
          aver = this.average(arr);
        } else {
          aver = this.average(this.powerDay[i].valleyPeriodPower);
        }
        this.valleyPowerAver.push(roundFix(aver));
      }
    },
    getDevice() {
      console.log(this.deviceList,"deviceList")
      this.psPowerAver.forEach((v, k) => {
        // debugger;
        var flag = 0;
        var list = [];
        if (this.valleyLoadPoor[k] > 10) {
          for (let j = 1; j < 100; j++) {
            for (let i = 0; i < this.deviceList.length; i++) {
              if (
                this.batteryType != this.deviceList[i].batteryType ||
                this.deviceList[i].istallWay != this.ruleForm.istallWay
              ) {
                console.log(
                  this.deviceList[i].istallWay,
                  this.deviceList[i],
                  j
                );
                continue;
              }
              let d1 = v - this.deviceList[i].power * 0.9;
              let c =
                parseFloat(v) + parseFloat(this.deviceList[i].power * 0.1);
              let p = this.deviceList[i].power * j;
              console.log(d1, p, c, this.deviceList[i].power * j);
              console.log("device:", this.deviceList[i], i, j);
              if (d1 < 0) {
                break;
              } else if (d1 < p && p < c) {
                var d = {
                  num: 0,
                  istallWay: 0,
                  capacity: 0,
                  power: 0,
                  prices: 0,
                  name: "",
                };
                var f = parseFloat(j * this.deviceList[i].capacity);
                d.num = j;
                
                // while (f > this.flatLoad[k]) {
                //   d.num = d.num - 1;
                //   f = parseFloat(d.num * this.deviceList[i].power);
                //   console.log(d.num,"111111",j,f,this.flatLoad[k])
                // }
                // console.log(d.num,"2222")


                while (f > this.valleyL[k]) {
                  d.num = d.num - 1;
                  f = parseFloat(d.num * this.deviceList[i].capacity);
                  console.log(d.num,"111111",j,f,this.flatLoad[k])
                }
                d.istallWay = this.deviceList[i].istallWay;
                d.capacity = this.deviceList[i].capacity;
                d.power = this.deviceList[i].power;
                d.prices = this.deviceList[i].prices;
                d.name = this.deviceList[i].name;
                list.push(d);
                console.log(list);
                var chazhi = parseFloat(v) - parseFloat(d.num * d.power);
                var f2 = parseFloat(
                  parseFloat(this.flatLoad[k]) - parseFloat(d.num * d.power)
                );
                console.log(f2, "-------------");
                var flag2 = 0;
                for (
                  let index = this.deviceList.length - 1;
                  index >= 0;
                  index--
                ) {
                  if (
                    chazhi >= this.deviceList[index].power &&
                    this.deviceList[index].power <= f2
                  ) {
                    let x = {
                      num: 0,
                      istallWay: 0,
                      capacity: 0,
                      power: 0,
                      prices: 0,
                      name: "",
                    };
                    x.num = 1;
                    x.istallWay = this.deviceList[index].istallWay;
                    x.capacity = this.deviceList[index].capacity;
                    x.power = this.deviceList[index].power;
                    x.prices = this.deviceList[index].prices;
                    x.name = this.deviceList[index].name;
                    if (x.istallWay == d.istallWay) {
                      list.push(x);
                      flag2 = 1;
                    }
                  }
                  if (flag2) {
                    break;
                  }
                }
                this.device.push(list);
                list = [];
                flag = 1;
                break;
              } else {
                break;
              }
            }
            if (flag) {
              break;
            }
          }
        } else {
          var d2 = {
            num: 0,
            istallWay: "",
            capacity: 0,
            power: 0,
            prices: 0,
            name: "",
          };
          console.log(d2);
          list.push(d2);
          console.log(list);
          this.device.push(list);
          list = [];
        }
      });
    },
    getValleyLoadPoor() {
      for (let i = 0; i < this.loadList.length; i++) {
        this.valleyLoadPoor[i] = roundFix(
          this.loadList[i] - this.valleyPowerAver[i]
        );
        this.flatLoad[i] = roundFix(
          parseFloat(
            parseFloat(this.loadList[i]) - parseFloat(this.flatPowerAver[i])
          )
        );
        this.valleyL[i] = this.valleyLoadPoor[i] * this.electricityDate.valleyPeriodDate;
      }
      console.log(this.flatLoad,this.loadList,this.flatPowerAver,this.valleyL);
    },
    //计算年损耗
    getLoss() {
      for (let i = 0; i < 10; i++) {
        this.loss[i] = roundFix(
          1 - i * (this.workDay * this.systemData.singleAttenuation)
        );
      }
    },
    getBoxInvest() {
      this.investment.box = 0;
      this.device.forEach((item) => {
        item.forEach((v) => {
          if (v.istallWay === "户外柜") {
            this.isOutdoor = true;
          }
          if (v.istallWay === "集装箱") {
            this.isBox = true;
          }
          this.investment.box = parseFloat(
            parseFloat(this.investment.box) +
              parseFloat(v.capacity * v.num * 1000 * v.prices)
          ).toFixed(2);
        });
      });
    },
    getBoxInvest1() {
      this.investment1.box =
        this.ruleForm.deviceNum *
        this.deviceList[this.ruleForm.deviceType].capacity *
        1000 *
        this.deviceList[this.ruleForm.deviceType].prices;
    },
    //获取项目总负荷、总容量
    getDeviceSum() {
      this.device.forEach((item) => {
        // console.log(v);
        item.forEach((v) => {
          this.deviceSum.power =
            parseFloat(v.power * v.num) + parseFloat(this.deviceSum.power);
          this.deviceSum.capacity =
            parseFloat(v.capacity * v.num) +
            parseFloat(this.deviceSum.capacity);
        });
      });
    },
    getDeviceSum1() {
      this.deviceSum1.power =
        this.ruleForm.deviceNum *
        this.deviceList[this.ruleForm.deviceType].power;
      this.deviceSum1.capacity =
        this.ruleForm.deviceNum *
        this.deviceList[this.ruleForm.deviceType].capacity;
      // console.log(this.deviceList,this.ruleForm.deviceType,this.ruleForm.deviceSum,'hhhhhhh',this.deviceSum1)
    },
    getSharkSum() {
      // debugger;
      if (this.hasShark) {
        if (this.sharpMonth[0] == "all") {
          this.sharpSum = 12;
        } else {
          this.sharpSum = this.sharpMonth.length;
          // console.log(this.sharpMonth);
        }
      } else {
        this.sharpSum = 0;
      }
    },
    chooseProfits() {
      this.getSharkSum();
      if (this.chargeWay == 1) {
        this.profitsSum = this.getProfits2(this.deviceSum, this.investment);
        this.monthPrice = this.getMonthProfits1(
          this.deviceSum,
          this.investment
        );
        this.profitsSumChart = this.getProfitsChart(this.profitsSum);
        this.sum = this.getProfitSum(this.profitsSum);
        if (this.ruleForm.isForce) {
          this.getDeviceSum1();
          this.getBoxInvest1();
          this.profitsSum1 = this.getProfits2(
            this.deviceSum1,
            this.investment1
          );
          this.monthPrice1 = this.getMonthProfits1(
            this.deviceSum1,
            this.investment1
          );
          this.profitsSumChart1 = this.getProfitsChart(this.profitsSum1);
          this.sum1 = this.getProfitSum(this.profitsSum1);
        }
      } else if (this.chargeWay == 2) {
        this.profitsSum = this.getProfits(this.deviceSum, this.investment);
        this.monthPrice = this.getMonthProfits2(
          this.deviceSum,
          this.investment
        );
        this.profitsSumChart = this.getProfitsChart(this.profitsSum);
        this.sum = this.getProfitSum(this.profitsSum);
        if (this.ruleForm.isForce) {
          this.getDeviceSum1();
          this.getBoxInvest1();
          console.log(this.investment1, "this.investment1", this.investment);
          this.profitsSum1 = this.getProfits(this.deviceSum1, this.investment1);
          this.monthPrice1 = this.getMonthProfits2(
            this.deviceSum1,
            this.investment1
          );
          this.profitsSumChart1 = this.getProfitsChart(this.profitsSum1);
          this.sum1 = this.getProfitSum(this.profitsSum1);
        }
      }
    },
    //一充一放
    getMonthProfits1(deviceSum, investment) {
      console.log("this.sharpMonth=", this.sharpMonth);
      let arr = [];
      let sum = 0;
      let c = 0;
      let d = 0;
      for (let i = 0; i < 12; i++) {
        let obj = {
          spending: 0, //充电支出
          income: 0, //放电收入
          earning: 0, //年度收益
          month: 0, //第N月
          investment: 0, //初始投资
          operations: 0, //运维费用
          earnings: 0, //第N年年总收益
          aEarnings: 0, //甲方收益
          bEarnings: 0, //乙方收益
          siteEarning: 0, //场地分成
          unsiteEarning: 0, //除建设方之外的收益
          charge: 0, //充电量
          discharge: 0, //放电量
          sumPorfits: 0, //第N年累计收益
          sumYearPorfits: 0, //总累计0-N年年收益
          aInvest: 0, //甲方初始投资
          bInvest: 0, //乙方初始投资
          sharpCharge: 0,
          sharpDischarge: 0,
          sharpEarnings: 0,
          peakCharge: 0,
          peakDischarge: 0,
          peakEarnings: 0,
          flatCharge: 0,
          flatDischarge: 0,
          flatEarnings: 0,
          valleyCharge: 0,
          valleyDischarge: 0,
          valleyEarnings: 0,
        };
        obj.month = i + 1;
        obj.investment = investment.box;
        let firstCharge = Math.min(
          this.firstChargeDate * deviceSum.power,
          deviceSum.capacity
        );
        let secondCharge = 0;
        obj.charge = (firstCharge + secondCharge) * this.workSum;
        obj.discharge = roundFix(obj.charge * this.systemData.efficiency);
        c += obj.charge;
        d += obj.discharge;
        if (this.firstChargeName == "flat") {
          obj.flatCharge += firstCharge * this.workSum;
        } else if (this.firstChargeName == "valley") {
          obj.valleyCharge += firstCharge * this.workSum;
        }
        if (this.secondChargeName == "flat") {
          obj.flatCharge += secondCharge * this.workSum;
        } else if (this.secondChargeName == "valley") {
          obj.valleyCharge += secondCharge * this.workSum;
        }
        let sharpFlag = false;
        if (this.sharpMonth.includes(i + 1)) {
          sharpFlag = true;
        } else if (this.sharpMonth[0] == "all") {
          sharpFlag = true;
        }
        if (sharpFlag) {
          obj.sharpDischarge = roundFix(
            Math.min(
              deviceSum.power * this.electricityDate.sharpDate,
              deviceSum.capacity * this.systemData.efficiency
            ) * this.workSum
          );
          console.log(i + 1, "this.sharpMonth");
        }
        let peak =
          deviceSum.capacity * this.workSum * this.systemData.efficiency -
          obj.sharpDischarge;
        obj.peakDischarge = roundFix(
          Math.min(
            peak,
            deviceSum.power *
              this.systemData.efficiency *
              this.workSum *
              this.electricityDate.peakDate
          )
        );
        obj.peakEarnings = roundFix(
          (obj.peakDischarge - obj.peakCharge) *
            this.electricityPrices.peakPrices
        );

        obj.flatEarnings = roundFix(
          (obj.flatDischarge - obj.flatCharge) *
            this.electricityPrices.flatPrices
        );
        obj.valleyEarnings = roundFix(
          (obj.valleyDischarge - obj.valleyCharge) *
            this.electricityPrices.valleyPeriodPrices
        );
        obj.sharpEarnings = roundFix(
          (obj.sharpDischarge - obj.sharpCharge) *
            this.electricityPrices.sharpPrices
        );
        obj.earnings = roundFix(
          obj.peakEarnings +
            obj.flatEarnings +
            obj.valleyEarnings +
            obj.sharpEarnings
        );
        sum += obj.earnings;
        arr.push(obj);
      }
      console.log(arr, "monthDate1", sum, c, d);
      return arr;
      // console.log(arr, "monthDate1", sum, c, d);
      // this.monthPrice = arr;
    },
    // 2充2放
    getMonthProfits2(deviceSum, investment) {
      console.log("2this.sharpMonth=", this.sharpMonth);
      let arr = [];
      let sum = 0;
      for (let i = 0; i < 12; i++) {
        let obj = {
          spending: 0, //充电支出
          income: 0, //放电收入
          earning: 0, //年度收益
          month: 0, //第N月
          investment: 0, //初始投资
          operations: 0, //运维费用
          earnings: 0, //第N年年总收益
          aEarnings: 0, //甲方收益
          bEarnings: 0, //乙方收益
          siteEarning: 0, //场地分成
          unsiteEarning: 0, //除建设方之外的收益
          charge: 0, //充电量
          discharge: 0, //放电量
          sumPorfits: 0, //第N年累计收益
          sumYearPorfits: 0, //总累计0-N年年收益
          aInvest: 0, //甲方初始投资
          bInvest: 0, //乙方初始投资
          sharpCharge: 0,
          sharpDischarge: 0,
          sharpEarnings: 0,
          peakCharge: 0,
          peakDischarge: 0,
          peakEarnings: 0,
          flatCharge: 0,
          flatDischarge: 0,
          flatEarnings: 0,
          valleyCharge: 0,
          valleyDischarge: 0,
          valleyEarnings: 0,
        };
        obj.month = i + 1;
        obj.investment = investment.box;

        let firstCharge = Math.min(
          this.firstChargeDate * deviceSum.power,
          deviceSum.capacity
        );
        let secondCharge = Math.min(
          this.secondChargeDate * deviceSum.power,
          deviceSum.capacity
        );
        obj.charge = roundFix((firstCharge + secondCharge) * this.workSum);
        obj.discharge = roundFix(obj.charge * this.systemData.efficiency);
        let discharge =
          (firstCharge + secondCharge) * this.systemData.efficiency;
        if (this.firstChargeName == "flat") {
          obj.flatCharge += roundFix(firstCharge * this.workSum);
        } else if (this.firstChargeName == "valley") {
          obj.valleyCharge += roundFix(firstCharge * this.workSum);
        }
        if (this.secondChargeName == "flat") {
          obj.flatCharge += roundFix(secondCharge * this.workSum);
        } else if (this.secondChargeName == "valley") {
          obj.valleyCharge += roundFix(secondCharge * this.workSum);
        }
        let sharpFlag = false;
        // console.log(this.sharpMonth,i+1,"------sharpMonth",this.sharpMonth.includes(i+1))
        if (this.sharpMonth.includes(i + 1)) {
          // console.log(i + 1,"this.sharpMonth");
          sharpFlag = true;
        } else if (this.sharpMonth[0] == "all") {
          sharpFlag = true;
        }
        console.log(i, sharpFlag);
        if (sharpFlag) {
          console.log("sharpMonth", this.electricityDate);
          obj.sharpDischarge = roundFix(
            Math.min(
              deviceSum.power * this.electricityDate.sharpDate,
              discharge
            ) *
              this.workSum *
              this.systemData.efficiency
          );
          console.log(
            "obj.sharpDischarge",
            obj.sharpDischarge,
            deviceSum.power,
            this.electricityDate.sharpDate
          );
        }
        let peak = obj.discharge - obj.sharpDischarge;
        obj.peakDischarge = roundFix(
          Math.min(
            peak,
            deviceSum.power *
              this.systemData.efficiency *
              this.workSum *
              this.electricityDate.peakDate
          )
        );
        obj.flatDischarge = roundFix(
          obj.discharge - obj.sharpDischarge - obj.peakDischarge
        );

        obj.peakEarnings = roundFix(
          (obj.peakDischarge - obj.peakCharge) *
            this.electricityPrices.peakPrices
        );

        obj.flatEarnings = roundFix(
          (obj.flatDischarge - obj.flatCharge) *
            this.electricityPrices.flatPrices
        );
        obj.valleyEarnings = roundFix(
          (obj.valleyDischarge - obj.valleyCharge) *
            this.electricityPrices.valleyPeriodPrices
        );
        obj.sharpEarnings = roundFix(
          (obj.sharpDischarge - obj.sharpCharge) *
            this.electricityPrices.sharpPrices
        );
        obj.earnings = roundFix(
          obj.peakEarnings +
            obj.flatEarnings +
            obj.valleyEarnings +
            obj.sharpEarnings
        );

        sum += obj.earnings;
        arr.push(obj);
      }
      console.log(arr, "monthDate2", sum);
      return arr;
      // this.monthPrice = arr;
      // console.log(arr, "monthDate2", sum);
    },
    getProfits(deviceSum, investment) {
      // console.log(this.loss, "loss");
      this.getSharkSum();
      var sum = investment.box;
      var sp = 0;
      var profitsSum = [];
      for (let i = 0; i < 10; i++) {
        let obj = {
          spending: 0, //充电支出
          income: 0, //放电收入
          earning: 0, //年度收益
          years: 0, //第N年
          investment: 0, //初始投资
          operations: 0, //运维费用
          earnings: 0, //第N年年总收益
          aEarnings: 0, //甲方收益
          bEarnings: 0, //乙方收益
          siteEarning: 0, //场地分成
          unsiteEarning: 0, //除建设方之外的收益
          charge: 0, //充电量
          discharge: 0, //放电量
          sumPorfits: 0, //第N年累计收益
          sumYearPorfits: 0, //总累计0-N年年收益
          aInvest: 0, //甲方初始投资
          bInvest: 0, //乙方初始投资
        };
        // console.log(this.device[0].power, this.device[0].num);
        obj.years = i + 1;
        obj.investment = investment.box;
        // debugger;
        let firstCharge =
          Math.min(this.firstChargeDate * deviceSum.power, deviceSum.capacity) *
          this.loss[i];
        let secondCharge =
          Math.min(
            this.secondChargeDate * deviceSum.power,
            deviceSum.capacity
          ) * this.loss[i];
        let charge = firstCharge + secondCharge;
        let discharge = charge * this.systemData.efficiency;
        obj.charge = charge * this.workDay;
        // console.log(this.loss[i], "***88888", obj.charge);
        obj.discharge = roundFix(obj.charge * this.systemData.efficiency);
        obj.spending = roundFix(
          (firstCharge * this.firstChargePrices +
            secondCharge * this.secondChargePrices) *
            this.workDay
        );
        // console.log(obj.spending,"------------------------")
        //含有尖峰月份 尖峰时段优先放电
        // debugger;
        let sharp = Math.min(
          this.electricityDate.sharpDate *
            deviceSum.power *
            this.loss[i] *
            this.systemData.efficiency,
          discharge
        );
        let peak1 = Math.min(
          this.electricityDate.peakDate *
            deviceSum.power *
            this.loss[i] *
            this.systemData.efficiency,
          discharge - sharp
        );
        let flat1 =
          discharge - sharp - peak1 > 0 ? discharge - sharp - peak1 : 0;
        // 剩下月份的电量高峰时段放出
        let otherPeak = Math.min(
          discharge,
          this.electricityDate.peakDate *
            deviceSum.power *
            this.loss[i] *
            this.systemData.efficiency
        );
        let flat = discharge - otherPeak > 0 ? discharge - otherPeak : 0;
        // console.log(discharge,"sharp=",sharp,)
        let income = roundFix(
          (this.electricityPrices.sharpPrices * sharp +
            this.electricityPrices.peakPrices * peak1 +
            this.electricityPrices.flatPrices * flat1) *
            this.sharpSum *
            this.workSum +
            (this.electricityPrices.peakPrices * otherPeak +
              this.electricityPrices.flatPrices * flat) *
              (12 - this.sharpSum) *
              this.workSum
        );
        obj.income = income;
        obj.earning = roundFix(obj.income - obj.spending);
        obj.operations = roundFix(this.investment.operations * obj.earning);
        obj.earnings = roundFix(obj.earning - obj.operations); //第N年收益
        // obj.sumPorfits = roundFix(obj.earnings - sum); //累计收益，减去初始投资的
        // sum = Math.abs(obj.sumPorfits);
        obj.sumYearPorfits = roundFix(
          parseFloat(sp) + parseFloat(obj.earnings)
        ); //1~N年积累收益
        obj.sumPorfits = roundFix(obj.sumYearPorfits - sum);
        // console.log(obj.earnings,obj.sumYearPorfits,obj.sumPorfits);
        sp = obj.sumYearPorfits;
        if (this.value === 1) {
          profitsSum.push(obj);
        } else if (this.value === 2) {
          obj.siteEarning = roundFix(obj.sumYearPorfits * this.investment.site);
          // obj.earnings = roundFix(obj.earnings - obj.siteEarning);
          obj.unsiteEarning = roundFix(obj.sumYearPorfits - obj.siteEarning);
          profitsSum.push(obj);
        } else if (this.value === 3) {
          obj.aInvest = roundFix(obj.investment * this.investment.aInvest);
          obj.bInvest = roundFix(obj.investment - obj.aInvest);
          obj.aEarnings = roundFix(
            obj.sumYearPorfits * this.investment.aInvest
          );
          obj.bEarnings = roundFix(obj.sumYearPorfits - obj.aEarnings);
          profitsSum.push(obj);
        }
      }

      return profitsSum;
    },
    getProfits2(deviceSum, investment) {
      this.getSharkSum();
      var sum = investment.box;
      var sp = 0;
      var profitsSum = [];
      for (let i = 0; i < 10; i++) {
        let obj = {
          spending: 0, //充电支出
          income: 0, //放电收入
          earning: 0, //年度收益
          years: 0, //第N年
          investment: 0, //初始投资
          operations: 0, //运维费用
          earnings: 0, //第N年年总收益
          aEarnings: 0, //甲方收益
          bEarnings: 0, //乙方收益
          siteEarning: 0, //场地分成
          unsiteEarning: 0, //除建设方之外的收益
          charge: 0, //充电量
          discharge: 0, //放电量
          sumPorfits: 0, //第N年累计收益
          sumYearPorfits: 0, //总累计0-N年年收益
          aInvest: 0, //甲方初始投资
          bInvest: 0, //乙方初始投资
        };
        obj.years = i + 1;
        obj.investment = investment.box;
        let charge =
          Math.min(
            roundFix(deviceSum.capacity),
            this.firstChargeDate * deviceSum.power
          ) * this.loss[i];
        let discharge = charge * this.systemData.efficiency;
        obj.charge =
          Math.min(
            roundFix(deviceSum.capacity),
            this.firstChargeDate * deviceSum.power
          ) *
          this.workDay *
          this.loss[i];
        // debugger;
        obj.discharge = roundFix(obj.charge * this.systemData.efficiency);

        obj.spending = roundFix(obj.charge * this.firstChargePrices);
        // console.log(obj.spending,"------------------------")
        //含有尖峰月份 尖峰时段优先放电
        // debugger;
        let sharp = Math.min(
          this.electricityDate.sharpDate * deviceSum.power * this.loss[i],
          discharge
        );
        let peak1 = Math.min(
          this.electricityDate.peakDate * deviceSum.power * this.loss[i],
          discharge - sharp
        );
        let flat1 =
          discharge - sharp - peak1 > 0 ? discharge - sharp - peak1 : 0;
        // 剩下月份的电量高峰时段放出
        let otherPeak = Math.min(
          discharge,
          this.electricityDate.peakDate * deviceSum.power * this.loss[i]
        );
        let flat = discharge - otherPeak > 0 ? discharge - otherPeak : 0;
        // console.log(discharge,"sharp=",sharp,)
        let income = roundFix(
          (this.electricityPrices.sharpPrices * sharp +
            this.electricityPrices.peakPrices * peak1 +
            this.electricityPrices.flatPrices * flat1) *
            this.sharpSum *
            this.workSum +
            (this.electricityPrices.peakPrices * otherPeak +
              this.electricityPrices.flatPrices * flat) *
              (12 - this.sharpSum) *
              this.workSum
        );
        obj.income = income;
        obj.earning = roundFix(obj.income - obj.spending);
        obj.operations = roundFix(this.investment.operations * obj.earning);
        obj.earnings = roundFix(obj.earning - obj.operations); //第N年收益
        // obj.sumPorfits = roundFix(obj.earnings - sum); //累计收益，减去初始投资的
        // sum = Math.abs(obj.sumPorfits);
        obj.sumYearPorfits = roundFix(
          parseFloat(sp) + parseFloat(obj.earnings)
        ); //1~N年积累收益
        obj.sumPorfits = roundFix(obj.sumYearPorfits - sum);
        // console.log(obj.earnings,obj.sumYearPorfits,obj.sumPorfits);
        sp = obj.sumYearPorfits;
        if (this.value === 1) {
          profitsSum.push(obj);
        } else if (this.value === 2) {
          obj.siteEarning = roundFix(obj.sumYearPorfits * this.investment.site);
          // obj.earnings = roundFix(obj.earnings - obj.siteEarning);
          obj.unsiteEarning = roundFix(obj.sumYearPorfits - obj.siteEarning);
          profitsSum.push(obj);
        } else if (this.value === 3) {
          obj.aInvest = roundFix(obj.investment * this.investment.aInvest);
          obj.bInvest = roundFix(obj.investment - obj.aInvest);
          obj.aEarnings = roundFix(
            obj.sumYearPorfits * this.investment.aInvest
          );
          obj.bEarnings = roundFix(obj.sumYearPorfits - obj.aEarnings);
          profitsSum.push(obj);
        }
        console.log(obj, "------************");
      }
      return profitsSum;
    },
    getProfitsChart(profitsSum) {
      let profitsSumChart = {
        years: [],
        // spending: [],
        // income: [],
        earnings: [],
        investment: [],
        operations: [],
        charge: [],
        discharge: [],
        aEarnings: [],
        bEarnings: [],
        siteEarning: [],
        unsiteEarning: [],
        sumPorfits: [],
        sumYearPorfits: [],
        aInvest: [],
        bInvest: [],
      };
      profitsSum.forEach((v) => {
        profitsSumChart.years.push(v.years);
        profitsSumChart.earnings.push(roundFix(v.earnings / 10000.0));
        profitsSumChart.investment.push(roundFix(v.investment / 10000.0));
        profitsSumChart.operations.push(roundFix(v.operations / 10000.0));
        profitsSumChart.charge.push(roundFix(v.charge / 1000.0));
        profitsSumChart.discharge.push(roundFix(v.discharge / 1000.0));
        profitsSumChart.aEarnings.push(roundFix(v.aEarnings / 10000.0));
        profitsSumChart.bEarnings.push(roundFix(v.bEarnings / 10000.0));
        profitsSumChart.siteEarning.push(roundFix(v.siteEarning / 10000.0));
        profitsSumChart.unsiteEarning.push(roundFix(v.unsiteEarning / 10000.0));
        profitsSumChart.sumPorfits.push(roundFix(v.sumPorfits / 10000.0));
        profitsSumChart.sumYearPorfits.push(
          roundFix(v.sumYearPorfits / 10000.0)
        );
        profitsSumChart.aInvest.push(roundFix(v.aInvest / 10000.0));
        profitsSumChart.bInvest.push(roundFix(v.bInvest / 10000.0));
      });
      console.log(profitsSumChart, "********profitsSumChart", profitsSum);
      return profitsSumChart;
    },
    getProfitSum(profitsSum) {
      console.log(profitsSum, "profitsSum");
      let sum = [];
      let obj = {
        investment: 0, //初始投资
        aInvest: 0,
        bInvest: 0,
        aEarnings: 0,
        bEarnings: 0,
        siteEarning: 0,
        earnings: 0,
      };
      obj.investment = profitsSum[9].investment;
      obj.aInvest = profitsSum[9].aInvest;
      obj.bInvest = profitsSum[9].bInvest;
      obj.aEarnings = profitsSum[9].aEarnings;
      obj.bEarnings = profitsSum[9].bEarnings;
      obj.siteEarning = profitsSum[9].siteEarning;
      obj.unsiteEarning = profitsSum[9].unsiteEarning;
      obj.earnings = profitsSum[9].earnings;
      obj.sumYearPorfits = profitsSum[9].sumYearPorfits;
      sum.push(obj);
      return sum;
    },
    getOnePdf() {
      var title = "单页报告";
      var dom = document.getElementById("pdf-container"); // 生成pdf的html内容
      html2Canvas(dom, {
        allowTaint: true,
        scrollY: 0,
        scrollX: 0,
        useCORS: true, // 开启跨院
        width: dom.offsetHeight, // 这个宽度，太扯淡了，固定1000，防止偏移！
        height: dom.offsetHeight,
      }).then(function (canvas) {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        var pdfWidth = ((contentWidth + 10) / 2) * 0.75;
        var pdfHeight = ((contentHeight + 200) / 2) * 0.75; // 500为底部留白
        var imgWidth = pdfWidth;
        var imgHeight = (contentHeight / 2) * 0.75; //内容图片这里不需要留白的距离
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new JsPDF("", "pt", [pdfWidth, pdfHeight]);
        pdf.addImage(pageData, "jpeg", 0, 0, imgWidth, imgHeight);
        pdf.save(title + ".pdf");
      });
    },
    getDate() {
      var date = new Date();
      this.dateTime = this.toDates(date.getTime());
    },
    toDates(times) {
      const date = new Date(times);
      const Y = date.getFullYear();
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      const Min =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      const dateTime = Y + "-" + M + "-" + D + " " + " " + H + ":" + Min;
      return dateTime;
    },
    toAzure(val = false, download = true) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.getDate();
          window.scrollTo(0, 0); //这行代码很重要，它让页面的滚动条跳到了最上方如果点击打印按钮的时候,滚动条没有在最上方,打印内容会是不完整的,体验也会差
          let title = this.name + this.dateTime + "方案报告";
          // console.log(title)
          this.getPdf(title, download) //download：false为不下载，这里调用了刚刚引用的全局函数，.then得到的值是base64位的pdf文件
            .then((res) => {
              // console.log(res);
              // console.log(res.size);
              if (val) {
                console.log("准备上传");
                // this.UploadPdf(res);
                // this.uploadPdfToAzure(res);
                handleFileSelect(res, title);
              } else {
                console.log("不上传");
              }
            });
        }, 1000);
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/ .el-form-item{
    margin-bottom: 0
}
/deep/ .el-table .el-table__cell{
  padding 5px 0 !important
}
/deep/ .el-table__header tr,
  .el-table__header th {
    padding: 0;
    height: 40px;
}
/deep/ .el-table__body tr,
  .el-table__body td {
    padding: 0;
    height: 40px;
}
.blackTheme{
  .report_box{
    background-color: #002140
  }
  .content{
    background-color: #222e4a
  }
  .report_box .report .content .introduction .table{
    border:0px
  }
  .container{
    border:0px
  }
}
.blackTheme /deep/ .el-table td.el-table__cell{
  color:#fff !important;
  border-bottom:1px solid  #222e4a  !important;
}
.blackTheme /deep/ .el-table th.el-table__cell.is-leaf{
  color:#fff !important;
  border-bottom:1px solid  #fff  !important;
}

.whiteTheme /deep/ .el-table td.el-table__cell{
  background-color:#fff !important;
  border-bottom:1px solid  #ebeef5  !important;
}
.whiteTheme /deep/ .el-table th.el-table__cell.is-leaf{
  background-color:#fff !important;
  border-bottom:1px solid  #ebeef5  !important;
}
.whiteTheme /deep/ .el-table--enable-row-hover .el-table__body tr:hover{
  background-color:#fff !important;
}
.whiteTheme{
  .report_box{
    background-color: #f2f5f7;
    color:#000
  }
  .content{
    background-color: #fff
  }
  .num{
    color:#000
  }
}
.report_box{
    width 100%
    height: 100vh;
    overflow-y:auto

    .header{
        width 100%
        height: 60px
        background-color: #fff
        display: flex
        justify-content: space-between
        .logo{
          display:flex
          img{
            height: 30px
            margin: 15px 20px
          }
          .vilion{
            line-height: 60px
            font-size: 20px
            color:#012fa7
            font-weight: 600
          }
        }
        .download{
          margin 10px
        }
    }
    .report{
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .content{
            width 60%
            height 100%

            margin: 20px 0
            padding 20px
            .introduction{
                width 100%
                height: 100%
                .table{
                    border: 1px solid #ddd
                }
            }
        }
    }
}
.table{
  margin 20px 0
  .tableContent{
    display flex
    justify-content center
    align-items: center;
    .el-table{
      width 48%
    }
    .table-line{
      width 52%
    }
  }
}
.firstTitle{
    font-size: 20px
    margin: 15px 0
}
.projectContent{
    font-size: 16px
    margin: 15px
    line-height: 30px
}
.img{
  display: flex;
  justify-content: space-evenly
}
.important{
  color:#1a73e8
  margin 5px
  // color yellow
  // font-size: 18px
}
.secTitle{
  height:30px
  font-size: 18px
  line-height 30px
}
.chart{
  margin-top 30px
}
.table-line{
  height 300px
}
.pointBox{
  margin 20px
  .point{
    margin 20px 0
  }
}
.num{
  display inline-block
  width 30px
  text-align: center
  background: #1a73e8
  color #fff
  border-radius: 50%
}
.tableHead{
  font-size 16px
  color red
}
.forceDiv{
  //border:1px dashed #1a73e8
}
.device{
  text-indent: 2em
  margin-bottom 10px;
}
.device::after{
  content:'',
  width 100px
  height 3px
  color red
}
//移动端适配
@media screen and (min-width:600px) and (max-width:1100px){
  .report{
    display block
    .content{
      width 100% !important
      padding 0
      margin 0
    }
  }
  // .report_box .report .content{
  //   padding 0 !important
  // }
  .table{
  margin 10px 0
  .tableContent{
    display block
    .el-table{
      width 100%
    }
    .table-line{
      width 100%
    }
  }
}
.firstTitle{
    margin 15px 7px !important
    font-size 16px !important
    font-weight 600
  }
  .secTitle{
  height:25px !important
  font-size: 16px !important
  line-height 25px !important
  }
  .pointBox{
    margin 0 !important
    .point{
      margin 10px !important
    }
  }
  .img{
    flex-direction column
    align-items center
    img{
      height 120px
      width 67%
    }
  }
}
@media screen and (max-width:600px) {
  .report{
    display block
    .content{
      width 100% !important
      padding 0 !important
      margin 0
    }
  }
  .num{
    width 20px !important
  }
  .table{
  margin 10px 0
  .tableContent{
    display block
    .el-table{
      width 100%
    }
    .table-line{
      width 100%
    }
  }
}
.projectContent{
    font-size: 12px
    margin: 15px
    line-height: 20px
}
  .firstTitle{
    margin 15px 7px !important
    font-size 16px !important
    font-weight 600
  }
  .secTitle{
  height:25px !important
  font-size: 16px !important
  line-height 25px !important
  }
  .pointBox{
    margin 0 !important
    .point{
      margin 10px !important
    }
  }
  .img{
    flex-direction column
    align-items center
    img{
      height 120px
      width 67%
    }
  }
  .download{
    display none
  }
  .header{
    display block !important
    .logo{
      justify-content space-between
    }
    .vilion{
      margin 0 20px
    }
  }

}
</style>
