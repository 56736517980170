import $ from "jquery";
var maxBlockSize = 256 * 1024; //Each file will be split in 256 KB.
var numberOfBlocks = 1;
var selectedFile = null;
var currentFilePointer = 0;
var totalBytesRemaining = 0;
var blockIds = new Array();
var blockIdPrefix = "block-";
var submitUri = null;
var bytesUploaded = 0;
// 选择上传文件，并设置azure的sasUrl，将sasUrl拆分，加入文件名称
export const handleFileSelect = (res, title) => {
  maxBlockSize = 256 * 1024;
  currentFilePointer = 0;
  totalBytesRemaining = 0;
  let pdfBase64Str = res;
  // console.log(res);
  // let title = "用户报告6";
  selectedFile = dataURLtoFile(pdfBase64Str, title + ".pdf"); //调用一下下面的转文件流函数
  // console.log(selectedFile);
  // console.log(selectedFile.slice);
  var file = new FormData();
  file.append("file", selectedFile);
  var fileSize = selectedFile.size;
  if (fileSize < maxBlockSize) {
    maxBlockSize = fileSize;
    // console.log("max block size = " + maxBlockSize);
  }
  totalBytesRemaining = fileSize;
  if (fileSize % maxBlockSize == 0) {
    numberOfBlocks = fileSize / maxBlockSize;
  } else {
    numberOfBlocks = parseInt(fileSize / maxBlockSize, 10) + 1;
  }
  console.log("total blocks = " + numberOfBlocks);
  var baseUrl =
    "https://storagetest02.blob.core.chinacloudapi.cn/container-pdf?sp=racwl&st=2021-11-04T09:15:07Z&se=2022-12-31T17:15:07Z&sv=2020-02-10&sr=c&sig=El67i8%2Fop82QQJ6af3pemTKgDZRLpYti7BLGI4dcSEU%3D";
  // console.log(baseUrl);
  // SAS URI 实际上表示 blob 容器的 URI。由于我必须创建一个用于上传文件的端点，因此我拆分了 URI（路径和查询）并将文件名附加到路径，然后将查询重新附加到末尾
  var indexOfQueryStart = baseUrl.indexOf("?");
  submitUri =
    baseUrl.substring(0, indexOfQueryStart) +
    "/" +
    selectedFile.name +
    baseUrl.substring(indexOfQueryStart);
  // console.log(submitUri);
  // uploadFileInBlocks(selectedFile);
  uploadFileInBlocks();
};
// console.log(selectedFile);
var reader = new FileReader();

reader.onloadend = function (evt) {
  if (evt.target.readyState == FileReader.DONE) {
    var uri =
      submitUri + "&comp=block&blockid=" + blockIds[blockIds.length - 1];
    var requestData = new Uint8Array(evt.target.result);
    // console.log(requestData, evt.target.result);
    $.ajax({
      url: uri,
      type: "PUT",
      data: requestData,
      processData: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
        xhr.setRequestHeader("Content-Length", requestData.length);
      },
      success: function (data, status) {
        console.log(data);
        console.log(status);
        bytesUploaded += requestData.length;
        var percentComplete = (
          (parseFloat(bytesUploaded) / parseFloat(selectedFile.size)) *
          100
        ).toFixed(2);
        $("#fileUploadProgress").text(percentComplete + " %");
        uploadFileInBlocks();
      },
      error: function (xhr, desc, err) {
        console.log(desc);
        console.log(err);
      },
    });
  }
};

function uploadFileInBlocks() {
  if (totalBytesRemaining > 0) {
    var fileContent = selectedFile.slice(
      currentFilePointer,
      currentFilePointer + maxBlockSize
    );
    var blockId = blockIdPrefix + pad(blockIds.length, 6);
    blockIds.push(btoa(blockId));
    reader.readAsArrayBuffer(fileContent);
    currentFilePointer += maxBlockSize;
    totalBytesRemaining -= maxBlockSize;
    if (totalBytesRemaining < maxBlockSize) {
      maxBlockSize = totalBytesRemaining;
    }
  } else {
    commitBlockList();
  }
}
function commitBlockList() {
  var uri = submitUri + "&comp=blocklist";
  // console.log(uri);
  var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
  for (var i = 0; i < blockIds.length; i++) {
    requestBody += "<Latest>" + blockIds[i] + "</Latest>";
  }
  requestBody += "</BlockList>";
  // console.log(requestBody);
  $.ajax({
    url: uri,
    type: "PUT",
    data: requestBody,
    beforeSend: function (xhr) {
      xhr.setRequestHeader("x-ms-blob-content-type", selectedFile.type);
      xhr.setRequestHeader("Content-Length", requestBody.length);
    },
    success: function (data, status) {
      console.log(data);
      console.log(status);
    },
    error: function (xhr, desc, err) {
      console.log(desc);
      console.log(err);
    },
  });
}
function pad(number, length) {
  var str = "" + number;
  while (str.length < length) {
    str = "0" + str;
  }
  return str;
}
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
