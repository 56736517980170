<template>
    <div class="container">
        <div class="name">{{name}}</div>
    </div>
</template>
<script>
export default {
    props: {
        name: { // 标题
            type: String,
            default: ''
        }
    },
}
</script>
<style scoped>
.container{
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
}
.name{
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
    box-sizing: border-box;
    border-left: 5px solid #40a9ff;
}

</style>
