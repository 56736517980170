<template>
  <div class="weatherLine">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/line");
export default {
  name: "dateLine",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    years: Array,
    earnings: Array,
    charge: Array,
    discharge: Array,
    investment: Array,
    sumPorfits: Array,
    sumYearPorfits: Array,
  },
  data() {
    return {};
  },
  mounted() {
    this.drawWeatherLine();
    window.addEventListener("resize", function () {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
    // console.log(this.peakPower,this.load)
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  watch: {
    charge() {
      this.drawWeatherLine();
    },
    discharge() {
      this.drawWeatherLine();
    },
    earnings() {
      this.drawWeatherLine();
    },
    investment() {
      this.drawWeatherLine();
    },
    sumPorfits() {
      this.drawWeatherLine();
    },
    sumYearPorfits() {
      this.drawWeatherLine();
    },
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      // 绘制图表
      const colors = ["#5470C6", "#91CC75", "#EE6666"];
      myChart.setOption(
        {
          color: colors,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          grid: {
            left: "3%",
            right: "3%",
            bottom: "3%",
            containLabel: true,
          },
          legend: {
            top: 10,
            itemWidth: 8,
            itemHeight: 8,
            icon: "circle",
            left: "center",
            padding: 0,
            textStyle: {
              color: "inherit",
              fontSize: 12,
              padding: [2, 0, 0, 0],
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: {
                alignWithLabel: true,
              },
              // prettier-ignore
              data: this.years,
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "电量",
              // min: 0,
              // max: 250,
              position: "right",
              // offset: 80,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[0],
                },
              },
              axisLabel: {
                formatter: "{value} Mkwh",
                textStyle: {
                  fontSize: 10,
                },
                // rotate:30
              },
            },
            {
              type: "value",
              name: "费用",
              // min: 0,
              // max: 25,
              position: "left",
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[1],
                },
              },
              axisLabel: {
                formatter: "{value} 万元",
                textStyle: {
                  fontSize: 10,
                },
                // rotate:-35
              },
            },
          ],
          series: [
            {
              name: "充电量（MkWh）",
              type: "bar",
              data: this.charge,
              yAxisIndex: 0,
              barWidth: 15,
              itemStyle: {
                normal: {
                  show: true,
                  // color: "#5dc7f0",
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#00D6F8",
                    },
                    {
                      offset: 1,
                      color: "#2391FF",
                    },
                  ]),
                  barBorderRadius: 10,
                },
              },
            },
            {
              name: "放电量（MkWh）",
              type: "bar",
              yAxisIndex: 0,
              data: this.discharge,
              barWidth: 15,
              itemStyle: {
                normal: {
                  show: true,
                  // color: "#7e68f6",
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#F9F871",
                    },
                    {
                      offset: 1,
                      color: "#FFC328",
                    },
                  ]),
                  barBorderRadius: 10,
                },
              },
            },
            {
              name: "初始投资（万元）",
              type: "line",
              yAxisIndex: 1,
              data: this.investment,
              itemStyle: {
                color: {
                  x: 0,
                  y: 1,
                  x2: 0,
                  y2: 0,
                  type: "linear",
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(245, 94, 94,0.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(245, 94, 94,1)",
                    },
                  ],
                },
              },
            },
            // {
            //   name: "年度收益（元）",
            //   type: "line",
            //   yAxisIndex: 1,
            //   data: this.earnings
            // },
            {
              name: "累计收益（万元）",
              type: "line",
              yAxisIndex: 1,
              data: this.sumYearPorfits,
              itemStyle: {
                color: {
                  x: 0,
                  y: 1,
                  x2: 0,
                  y2: 0,
                  type: "linear",
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(116, 21, 219,0.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(116, 21, 219,1)",
                    },
                  ],
                },
              },
            },
          ],
        },
        true
      );
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.weatherLine {
    height: 100%;
    width: 100%;
}
</style>
