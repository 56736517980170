<template>
  <div class="weatherLine">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/line");
export default {
  name: "dateLine",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    years: {
      type: Array,
      default() {
        return [];
      },
    },
    charge: Array,
    discharge: Array,
    // investment: Array,
    aEarnings: Array,
    bEarnings: Array,
    // sumPorfits:Array
    aInvest: Array,
    bInvest: Array,
  },
  data() {
    return {};
  },
  mounted() {
    this.drawWeatherLine();
    window.addEventListener("resize", function () {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
    // console.log(this.peakPower,this.load)
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  watch: {
    charge() {
      this.drawWeatherLine();
    },
    discharge() {
      this.drawWeatherLine();
    },
    aEarnings() {
      this.drawWeatherLine();
    },
    // investment() {
    //   this.drawWeatherLine();
    // },
    bEarnings() {
      this.drawWeatherLine();
    },
    // sumPorfits() {
    //     this.drawWeatherLine()
    // },
    aInvest() {
      this.drawWeatherLine();
    },
    bInvest() {
      this.drawWeatherLine();
    },
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      // 绘制图表
      const colors = [
        "#5470C6",
        "#91CC75",
        "#EE6666",
        "#65B581",
        "#FFCE34",
        "#FD665F",
      ];
      myChart.setOption(
        {
          color: colors,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          // grid: {
          //   right: "20%",
          // },
          // toolbox: {
          //   feature: {
          //     dataView: { show: true, readOnly: false },
          //     restore: { show: true },
          //     saveAsImage: { show: true },
          //   },
          // },
          legend: {
            textStyle: {
              fontSize: 10,
            },
            data: [
              "充电量（MkWh）",
              "放电量（MkWh）",
              // "初始投资（元）",
              "资金方投资（万元）",
              "资金方收益（万元）",
              "建设方投资（万元）",
              "建设方分成（万元）",
              // "累计收益（元)"
              // "初始投资（元）",
              // "运维费用（元）",
            ],
          },
          grid: {
            left: "3%",
            right: "3%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              axisTick: {
                alignWithLabel: true,
              },
              // prettier-ignore
              data: this.years,
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "电量",
              position: "right",
              // offset: 80,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[0],
                },
              },
              axisLabel: {
                formatter: "{value} Mkwh",
              },
            },
            {
              type: "value",
              name: "费用",
              // min: 0,
              // max: 25,
              position: "left",
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[1],
                },
              },
              axisLabel: {
                formatter: "{value} 万元",
              },
            },
          ],
          series: [
            {
              name: "充电量（MkWh）",
              type: "bar",
              data: this.charge,
              yAxisIndex: 0,
            },
            {
              name: "放电量（MkWh）",
              type: "bar",
              yAxisIndex: 0,
              data: this.discharge,
            },
            {
              name: "资金方投资（万元）",
              type: "line",
              yAxisIndex: 1,
              data: this.aInvest,
            },
            {
              name: "资金方收益（万元）",
              type: "line",
              yAxisIndex: 1,
              data: this.aEarnings,
            },
            {
              name: "建设方投资（万元）",
              type: "line",
              yAxisIndex: 1,
              data: this.bInvest,
            },
            {
              name: "建设方分成（万元）",
              type: "line",
              yAxisIndex: 1,
              data: this.bEarnings,
            },
            // {
            //   name: "累计收益（元）",
            //   type: "line",
            //   yAxisIndex: 1,
            //   data: this.sumPorfits
            // },
          ],
        },
        true
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
.weatherLine {
    height: 100%;
    width: 100%;
}
</style>
