<template>
  <div class="weatherLine">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/bar");
export default {
  name: "bar",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    xData: {
      type: Array,
      default() {
        return [];
      },
    },
    date: Array,
    peakPower: Array,
    valleyPeriodPower: Array,
    flatPower: Array,
    sharpPower: Array,
  },
  data() {
    return {};
  },
  watch: {
    peakPower() {
      this.drawWeatherLine();
    },
    valleyPeriodPower() {
      this.drawWeatherLine();
    },
    flatPower() {
      this.drawWeatherLine();
    },
    sharpPower() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    window.addEventListener("resize", function () {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      // 绘制图表
      var option = {
        legend: {
          top: 10,
          itemWidth: 8,
          itemHeight: 8,
          icon: "circle",
          left: "center",
          padding: 0,
          textStyle: {
            color: "inherit",
            fontSize: 12,
            padding: [2, 0, 0, 0],
          },
        },
        grid: {
          containLabel: true,
          left: 40,
          right: 80,
          bottom: 20,
          top: 80,
        },
        // grid: {
        //   left: "3%",
        //   right: "15%",
        //   // bottom: "3%",
        //   containLabel: true,
        // },
        // toolbox: {
        //   show: true,
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ["line", "bar"] },
        //     restore: { show: true },
        //     saveAsImage: { show: true },
        //   },
        // },
        calculable: true,
        xAxis: [
          {
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#6e7079",
              },
              show: false,
            },
            type: "category",
            // prettier-ignore
            data: this.date,
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                color: "#6e7079",
              },
            },
          },
        ],
        series: [
          {
            name: "高峰用电量（kWh）",
            type: "bar",
            data: this.peakPower,

            itemStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                type: "linear",
                colorStops: [
                  {
                    offset: 0,
                    color: "#074fa6",
                  },
                  {
                    offset: 1,
                    color: "#00D7E9",
                  },
                ],
              },
            },
            markPoint: {
              symbolSize: 30,
              itemStyle: {
                // normal: {
                label: {
                  show: true,
                  // color: "red", //气泡中字体颜色
                  fontSize: 8,
                },
              },
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
          },
          {
            name: "尖峰用电量（kWh）",
            type: "bar",
            data: this.sharpPower,
            itemStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                type: "linear",
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(253, 210, 6,0.3)",
                  },
                  {
                    offset: 1,
                    color: "rgba(253, 210, 6,1)",
                  },
                ],
              },
            },
            markPoint: {
              symbolSize: 30,
              itemStyle: {
                // normal: {
                label: {
                  show: true,
                  // color: "red", //气泡中字体颜色
                  fontSize: 8,
                },
                // },
              },
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
          },
          {
            name: "谷段用电量（kWh）",
            type: "bar",
            data: this.valleyPeriodPower,
            itemStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                type: "linear",
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(245, 94, 94,0.3)",
                  },
                  {
                    offset: 1,
                    color: "rgba(245, 94, 94,1)",
                  },
                ],
              },
            },
            markPoint: {
              symbolSize: 30,
              itemStyle: {
                // normal: {
                label: {
                  show: true,
                  // color: "red", //气泡中字体颜色
                  fontSize: 8,
                },
                // },
              },
              data: [
                { name: "Max", type: "max" },
                { name: "Min", type: "min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
          },
          {
            name: "平段用电量（kWh）",
            type: "bar",
            data: this.flatPower,
            itemStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                type: "linear",
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(116, 21, 219,0.3)",
                  },
                  {
                    offset: 1,
                    color: "rgba(116, 21, 219,1)",
                  },
                ],
              },
            },
            markPoint: {
              symbolSize: 30,
              itemStyle: {
                // normal: {
                label: {
                  show: true,
                  // color: "red", //气泡中字体颜色
                  fontSize: 8,
                },
                // },
              },
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
          },
        ],
      };
      // var option = {
      //     tooltip: {
      //     trigger: "axis",
      //     },
      //     legend: {
      //     data: [
      //         "峰段用电量（Kw/h）",
      //         "谷段用电量（Kw/h）",
      //         "平段用电量（Kw/h）",
      //     ],
      //     },
      //     // grid: {
      //     //     left: "2%",
      //     //     right: "18%",
      //     //     bottom: "3%",
      //     //     containLabel: true,
      //     // },
      //     toolbox: {
      //     show: true,
      //     feature: {
      //         dataView: { show: true, readOnly: false },
      //         magicType: { show: true, type: ["line", "bar"] },
      //         restore: { show: true },
      //         saveAsImage: { show: true },
      //     },
      //     },
      //     calculable: true,
      //     xAxis: [
      //     {
      //         type: "category",
      //         data: this.date,
      //     },
      //     ],
      //     yAxis: [
      //     {
      //         type: "value",
      //     },
      //     ],
      //     series: [
      //         {
      //             type: "bar",
      //             name: "峰段用电量（Kw/h）",
      //             data: this.peakPower,
      //             barWidth: 40,
      //             markPoint: {
      //                 data: [
      //                     { type: "max", name: "Max" },
      //                     { type: "min", name: "Min" },
      //                 ],
      //             },
      //             markLine: {
      //             data: [{ type: "average", name: "Avg" }],
      //             },
      //         },
      //         {
      //             type: "bar",
      //             name: "谷段用电量（Kw/h）",
      //             data: this.valleyPeriodPower,
      //             barWidth: 40,
      //             markPoint: {
      //                 data: [
      //                     { type: "max", name: "Max" },
      //                     { type: "min", name: "Min" },
      //                 ],
      //             },
      //             markLine: {
      //             data: [{ type: "average", name: "Avg" }],
      //             },
      //         },
      //         {
      //             type: "bar",
      //             name: "平段用电量（Kw/h）",
      //             data: this.flatPower,
      //             barWidth: 40,
      //             markPoint: {
      //                 data: [
      //                     { type: "max", name: "Max" },
      //                     { type: "min", name: "Min" },
      //                 ],
      //             },
      //             markLine: {
      //             data: [{ type: "average", name: "Avg" }],
      //             },
      //         }
      //     ]
      // };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.weatherLine {
    height: 100%;
    width: 100%;
}
</style>
