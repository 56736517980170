export function getHour(s1, s2) {
  var reDate = /\d{4}-\d{1,2}-\d{1,2} /;
  s1 = new Date((reDate.test(s1) ? s1 : "2017-1-1 " + s1).replace(/-/g, "/"));
  s2 = new Date((reDate.test(s2) ? s2 : "2017-1-1 " + s2).replace(/-/g, "/"));
  //   console.log(s1,s2);
  var ms = s2.getTime() - s1.getTime();
  // console.log(ms);
  if (ms < 0) return 0;
  return parseFloat(ms / 1000 / 3600).toFixed(2);
  //   return Math.floor(ms / 1000 / 60 / 60);
}

export function getStartTime(s1) {
  var reDate = /\d{4}-\d{1,2}-\d{1,2} /;
  s1 = new Date((reDate.test(s1) ? s1 : "2017-1-1 " + s1).replace(/-/g, "/"));
  var x = Date.parse(s1) - 30 * 60 * 1000;
  var date = new Date(x);
  const H = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const Min =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  return H+':'+Min;
}
