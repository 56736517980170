<template>
  <div :class="bgTheme">
    <section>
      <el-table
        :show-header="false"
        :data="tableData"
        border
        :cell-style="columnStyle"
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column prop="name" align="center"></el-table-column>
        <el-table-column prop="value" align="center"></el-table-column>
      </el-table>
    </section>
  </div>
</template>
<script>
export default {
  name: "TransverseTable",
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    bgTheme: {
      type: String,
      default: "blackTheme",
    },
  },
  data() {
    return {};
  },
  methods: {
    // 自定义列背景色
    columnStyle({ columnIndex }) {
      if (columnIndex == 0) {
        // 修改每行第一个单元格的背景色
        return this.bgTheme == "blackTheme"
          ? "background:#00476A;"
          : "background:#f3f6fc;color:#606266;border:1px solid #ebeef5";
      } else {
        return this.bgTheme == "blackTheme"
          ? "background:#001b39;"
          : "background:#fff;color:#606266;border:1px solid #ebeef5";
      }
    },
  },
};
</script>
