<template>
  <div class="container flex items-middle">
    <div class="img"><img src="@/assets/imgs/u450.svg" /></div>
    <div class="name" :style="{ fontSize: fontSize }">{{ name }}</div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      // 标题
      type: String,
      default: "",
    },
    fontSize: {
      // 标题
      type: String,
      default: "20px",
    },
    color: {
      type: String,
      default: "#fff",
    },
  },
  watch: {
    name(newValue) {
      this.name = newValue;
    },
  },
};
</script>
<style scoped>
.container {
  margin: 10px;
  width: 100%;
  box-sizing: border-box;
  /* border-bottom: 1px solid #dddddd; */
  /* box-shadow: 0 0 3px #12ebfb; */
  border-radius: none !important;
  /* border-bottom: 1px solid #12ebfb; */
}
img {
  width: 15px;
}
.name {
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
